.cert-page {
  * {
    box-sizing: border-box;
    margin: 0;
  }
}

// search certificate

.cert-page {
  height: 100vh;
  background: #ffffff;
  font-size: 16px;

  .cert-appbar {
    top: 0;
    position: fixed;
    width: 100%;
    font-size: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    background: #ffffff;
  }
  .cert-s-content {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10vw;
    .cert-s-img {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;

      img {
        min-width: 400px;
        width: 40vw;
      }
    }
    .cert-search-div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1;

      .search-div {
        width: 350px;
        background: rgb(247, 247, 247);
        border-radius: 15px;
        padding: 2em;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .ec-title {
          text-align: center;
          font-size: 1.5em;
        }
        .inp {
          width: 100%;
          height: 40px;
          border: none;
          border-radius: 5px;
          outline: none;
          padding-inline: 10px;
          font-size: 1.2em;
          color: #5c5a5a;
          &:focus {
            outline: #4699f2 2px solid;
          }
          &::placeholder {
            color: #b8b8b8;
          }
        }
        .btn-check {
          width: 100%;
          height: 50px;
          background: #4699f2;
          border: none;
          outline: none;
          border-radius: 5px;
          font-size: 1.3em;
          color: white;
        }
      }
    }
  }
  @media (max-width: 850px) {
    .cert-appbar {
      font-size: 1.8em;
      text-align: center;
    }
    .cert-s-content {
      flex-direction: column;
      position: relative;
      top: 100px;
      height: calc(100vh - 100px);

      .cert-s-img {
        justify-content: flex-end;
      }
    }
  }
}

// view certificate
.cert-view-body {
  display: flex;
  align-items: center;
  height: 100vh;
  .oa-logo {
    position: absolute;
    top: 20px;
    left: 20px;

    a {
      img {
        height: 50px;
      }
    }
    @media (max-width: 500px) {
      a {
        img {
          height: 30px;
        }
      }
    }
  }
  .student-info-div {
    padding: 20px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .profile-main {
      * {
        margin: 0;
        box-sizing: border-box;
      }
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      font-size: 16px;

      .profile-image {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        border: 5px solid #c8eeff;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .st_name {
        font-size: 2em;
        font-weight: 600;
      }
      .email {
        font-size: 1.3em;
        color: gray;
      }
    }
    .hr {
      margin-block: 20px;
      width: 70%;
      height: 2px;
      background: linear-gradient(90deg, transparent, #e0e0e0, transparent);
    }
    .cert-content {
      * {
        margin: 0;
        box-sizing: border-box;
      }
      font-size: 16px;
      width: 60%;
      .cont-header {
        font-size: 1.2em;
      }
      .cont-value {
        color: #52adf7;
        font-size: 1.2em;
        .skills-chip {
          display: inline-block;
          box-sizing: border-box;
          padding-inline: 15px;
          padding-block: 5px;
          background: #f8f8f8;
          margin: 5px;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }
  }
  .cert-card-div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-left: 2px solid rgb(243, 243, 243);
    img {
      width: 80%;
    }

    // button,
    a {
      margin-top: 10px;
      height: 40px;
      width: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #52adf7;
      border-radius: 5px;
      font-size: 16px;
      text-decoration: none;
      color: white;
      outline: none;
      border: none;
    }
  }
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 20px;
    padding: 20px;

    .student-info-div {
      .hr {
        width: 100%;
      }
      .cert-content {
        width: auto;
        max-width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .cert-card-div {
      border-top: 2px solid rgb(243, 243, 243);
      padding-top: 15px;
      img {
        width: 95%;
      }
      a {
        margin: 20px;
      }
    }
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  .cert-card-div {
    img {
      visibility: visible;
    }
  }
}
