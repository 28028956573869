@import "../../../Scss/ScssMixins.scss";

%header {
  background-color: rgba(245, 245, 245, 0.911);
  color: rgb(1, 103, 146);
  height: 50px;
  @include flex(center, center, row, nowrap);
}

%router {
  @include flex(center, flex-start, column, nowrap);
  text-decoration: none;
  margin: 17px 20px;
  color: aliceblue;
}

.mobileSidebar-Row {
  @include flex(center, flex-start, row, nowrap);
  position: fixed;
  top: 0%;
  right: 0%;
  white-space: nowrap;
  z-index: 4;

  .mobileSidebar-CloseBtn {
    display: none;
  }

  .mobileSidebar-CloseBtn-active {
    @include flex(center, center, row, nowrap);
    @include box(50px, 50px, rgb(0, 101, 148), 50%);
    color: aliceblue;
    font-size: x-large;
    margin-top: 110px;
    margin-right: 10px;
    cursor: pointer;
    transition: all 0.2s;
    z-index: 1;
  }
  .mobileSidebar {
    overflow-y: auto;
    background-color: rgb(1, 103, 146);
    height: 100vh;
    width: 0px;
    margin-top: 100px;
    transition: all 0.4s;

    .mobileSidebar-EnrolledCourses {
      .mobileSidebar-EnrolledCourses-Heading {
        @extend %header;
        //   position: relative;

        //   &:before {
        //     content: "";
        //     left: -20px;
        //     position: absolute;
        //     height: 50px;
        //     width: 50px;
        //     border-radius: 50%;
        //     background-color: aqua;
        //   }
      }
      .mobileSidebar-router {
        @extend %router;
      }
    }

    .mobileSidebar-Routers {
      .mobileSidebar-Routers-Heading {
        @extend %header;
      }

      .mobileSidebar-router {
        @extend %router;
      }
    }

    .mobileSidebar-Row-Logout {
      @include flex(center, center, column, nowrap);
      .mobileSidebar-Logout {
        text-decoration: none;
        height: 40px;
        width: 120px;
        margin: 15px;
        @include flex(center, center, column, nowrap);
        background-color: whitesmoke;
        color: rgba(255, 0, 0, 0.726);
        border-radius: 5px;
      }
    }
  }
}

@media (min-width: 1100px) {
  .mobileSidebar-Row {
    display: none;
  }
}
