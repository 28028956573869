@import "../../Scss/ScssMixins.scss";

%buttonHover {
  &:hover {
    background-color: rgb(0, 179, 255);
  }
}

.register {
  @include box(calc(100vh - 100px), 100%, rgb(42, 157, 208));
  @include flex(center, center, row, nowrap);

  @include mediaquery-breakpoint(1200px) {
    height: auto;
  }

  .register-Card {
    @include box(auto, auto, rgb(1, 102, 147), 10px);
    @include flex(center, flex-start, column, nowrap);
    // height: 880px;
    max-width: 1200px;
    color: aliceblue;
    box-sizing: border-box;
    padding: 60px 60px;

    @include mediaquery-breakpoint(1200px) {
      @include box(100%, 100%, rgb(1, 102, 147), 0px);
    }

    @include mediaquery-breakpoint(600px) {
      padding: 60px 10px;
    }

    .title {
      margin-top: 0%;
      width: 100%;
      text-align: center;
      font-weight: 400;
      font-size: 30px;

      @include mediaquery-breakpoint(600px) {
        font-size: 25px;
      }
    }

    .upload-Img-Row {
      @include flex(space-between, center, row, nowrap);
      width: 320px;

      .register-Card-Circle {
        @include box(150px, 150px, rgb(193, 193, 193), 50%);
        margin: 10px 0px 30px 0px;
        border: 5px solid rgb(0, 149, 255);
        overflow: hidden;

        @include mediaquery-breakpoint(600px) {
          @include box(100px, 100px, rgb(193, 193, 193), 50%);
          margin: 10px 0px 30px 10px;
        }

        img {
          @include box(100%, 100%);
          object-fit: cover;
        }
        .upload {
          @include box(100%, 100%);
          @include flex(center, center, center, nowrap);
        }
      }

      .upload-Btn {
        @include box(40px, 120px, rgb(0, 78, 122), 5px);
        @include flex(center, center, row, nowrap);
        cursor: pointer;
        color: white;
        @extend %buttonHover;
      }
    }

    .register-Card-Row {
      @include flex(center, center, row, wrap);
      max-width: 920px;
      margin: auto;
      gap: 40px;

      @include mediaquery-breakpoint(600px) {
        display: block;
        width: 90%;
      }

      .register-Card-Textfield {
        @include flex(center, flex-start, column, wrap);
        // background-color: aqua;
      }

      input,
      select {
        @include box(45px, 200px, "", 2px);
        border: none;
        padding-left: 15px;
        font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
          sans-serif;
        font-size: large;
        box-sizing: border-box;
        &:focus {
          outline: none;
        }

        @include mediaquery-breakpoint(600px) {
          margin: 10px auto;
          width: 100%;
        }
      }
    }

    .register-Card-Btn {
      @include box(50px, 160px, rgb(0, 80, 126), 10px);
      @include flex(center, center, row, nowrap);
      cursor: pointer;
      margin-top: 45px;
      @extend %buttonHover;

      @include mediaquery-breakpoint(600px) {
        margin: 10px auto;
        width: 90%;
      }
    }
  }
}

// @import "RegisterMediaquery.scss";
