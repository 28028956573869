@mixin iconbar {
  .iconbar {
    background-color: rgb(0, 51, 85);
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .icons {
      margin: 10px 20px 10px 20px;
      font-size: 25px;
      color: aliceblue;
      cursor: pointer;
      transition: all;

      &:hover {
        fill: black;
      }
    }
  }
}
