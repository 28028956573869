@import "../../../Scss/ScssMixins.scss";
@import "./ReviewsResponsive.scss";

%Btn {
  font-size: 40px;
  color: rgb(0, 100, 139);
  cursor: pointer;
}

.reviews {
  font-size: 1.6rem;
  margin: 25px 0px;
  width: 100%;
  background-color: white;
  overflow: hidden;
  color: rgb(92, 90, 90);
  @include flex(center, center, column, nowrap);

  > p {
    margin: 10px 0px;
    &::before {
      display: inline-block;
      content: "";
      margin-bottom: 3px;
      margin-right: 5px;
      height: 1px;
      width: 20px;
      background-color: black;
    }
    &::after {
      display: inline-block;
      content: "";
      margin-bottom: 3px;
      margin-left: 5px;
      height: 1px;
      width: 20px;
      background-color: black;
    }
  }

  > h1 {
    margin: 0%;
    color: rgb(0, 174, 255);
    // font-variant-caps: all-petite-caps;
    font-weight: 300;
  }

  > .reviews-Row {
    position: relative;
    height: 500px;
    overflow: hidden;
    @include flex(center, center, row, nowrap);
    width: 1000px;
    // background-color: rgba(255, 255, 255, 0.876);

    > .reviews-Card {
      transition: all 1s;
      margin: 60px auto;
      height: 350px;
      max-width: 800px;
      @include flex(space-between, center, row, nowrap);
      border-radius: 20px;
      box-shadow: 2px 2px 100px 1px rgba(0, 0, 0, 0.055);
      position: relative;

      > .image {
        position: relative;
        right: 50px;
        width: 150px;
        height: 200px;
        background-size: cover;
        border-radius: 30px 5px 5px 5px;
        // background-color: aqua !important;
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          // background-color: rgba(23, 127, 212, 0.191);
          // backdrop-filter: blur(2px);
          transition: all 1s;
        }
        &:hover::before {
          background-color: rgba(23, 127, 212, 0.191);
          backdrop-filter: blur(1px);
        }
      }

      > .para {
        max-width: 600px;
        p {
          margin: 10px 25px;
          &:nth-of-type(2) {
            margin-top: 30px;
            color: rgb(0, 81, 173);
            letter-spacing: 2;
          }
        }
        // .line {
        //   width: 120px;
        //   border: 1px solid aqua;
        // }
      }

      &:nth-of-type(1) {
        // display: none;
        background-color: rgba(153, 142, 214, 0.027);
      }

      &:nth-of-type(2) {
        display: none;
        background-color: rgba(153, 142, 214, 0.027);
      }

      &:nth-of-type(3) {
        display: none;
        background-color: rgba(153, 142, 214, 0.027);
      }

      &:nth-of-type(4) {
        display: none;
        background-color: rgba(153, 142, 214, 0.027);
      }

      &:nth-of-type(5) {
        display: none;
        background-color: rgba(153, 142, 214, 0.027);
      }
    }
  }
  > .reviews-Dots {
    height: 50px;
    width: 180px;
    // background-color: rgba(255, 0, 0, 0.232);
    @include flex(space-between, center, row, nowrap);

    > .circle {
      height: 15px;
      width: 15px;
      border-radius: 50%;
      cursor: pointer;
      border: 1px solid rgb(0, 118, 165);
      background-color: transparent;

      &:nth-of-type(1) {
        background-color: rgb(0, 72, 100);
      }
    }
  }
}
