@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,600;1,700;1,800;1,900&family=Ubuntu:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
}
html {
  font-size: 9px;
}

@media (min-width: 600px) {
  html {
    font-size: 10px;
  }
}

body {
  font-family: "Poppins", sans-serif, "Ubuntu", sans-serif;
  /* font-family: "Montserrat", sans-serif; */
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(92, 90, 90);
  min-height: 100%;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f12d;
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
  background: rgb(3, 116, 197);
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #555;
}
