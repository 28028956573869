.howItWorks {
  font-family: "Poppins", sans-serif;
  width: 100%;
  font-size: 1.6rem;
  margin-block: 20px;
}

.howItWorks img {
  width: 100%;
}

.howItWorks > p {
  padding: 15px;
  text-align: center;
}

.howItWorks > .row {
  width: 95%;
  max-width: 1200px;
  margin-inline: auto;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

.howItWorks > h1 {
  text-align: center;
  color: rgb(0, 174, 255);
  font-weight: 400;
  font-size: 25px;
}

.howItWorks > .row > .col {
  flex-basis: calc(100% / 3 - 30px);
  flex-grow: 1;
  text-align: justify;
}

@media only screen and (max-width: 900px) {
  .howItWorks > .row > .col {
    flex-basis: auto;
    max-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  .howItWorks > .row > .col {
    flex-basis: auto;
  }
}
