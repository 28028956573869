.all-collection {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .mcq-collection-card {
    height: 200px;
    width: 200px;
    background: #969696;
    padding: 10px;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 15px;

    cursor: pointer;
    transition: 0.2s;
    &:hover {
     
      transform: scale(1.05);
    }
    img {
      height: 70%;
      width: 70%;
      object-fit: contain;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .all-collection {
    justify-content: center;
  }
}
