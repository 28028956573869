// @media only screen and (max-width: 1200px) {
//   .offlineDialog {
//     margin-top: 120px;
//   }
// }

// @media only screen and (max-width: 950px) {
//   .offlineDialog {
//     margin-top: 80px;
//   }
// }

@media only screen and (max-width: 850px) {
  .offlineDialog {
    // margin-top: 120px;
    // background-color: burlywood;

    .card {
      // height: 100%;
      // width: 100%;
      background-color: aliceblue;
      // @include flex(center, center, column, nowrap);
      .row {
        // background-color: antiquewhite;
        @include flex(center, center, column, nowrap);
        > img {
          display: none;
        }
      }
    }
  }
}
