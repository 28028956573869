@import "../../../../Scss/ScssMixins.scss";

%selection {
  &::selection {
    background-color: transparent;
  }
}
.login-Card-Top-Textfield {
  display: flex;
  align-items: center;
  gap: 15px; // Adjust spacing between dropdown and input
  margin-bottom: 15px; // Add spacing between this field and the next element
  margin-top: 10px;
  .country-Dropdown {
    font-size: 1.2rem;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.753);
    &:focus {
      outline: 2px solid rgb(0, 101, 126);
      // box-shadow: 2px 2px 20px 1px rgba(0, 174, 255, 0.11);
      // border: 1px solid rgb(0, 101, 126);
    }
    width:97px; // Adjust width as needed
    height: 41px;
    margin-bottom: -9px;
    
  }

  .mobileInput {
    font-size: 1.3rem;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.753);
    &:focus {
      outline: 2px solid rgb(0, 101, 126);
      // box-shadow: 2px 2px 20px 1px rgba(0, 174, 255, 0.11);
      // border: 1px solid rgb(0, 101, 126);
    }
    width: 158px; // Adjust width as needed
    height: 41px;
    color: rgb(0, 0, 0);
    margin-bottom: -9px;
  }
  .mobileInput::placeholder {
    color: rgba( 0, 0, 0,0.753); // Change to your desired color
            
  }
}




.offlineDialog {
  position: fixed;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.447);
  z-index: 99;
  display: none;
  .card {
    color: aliceblue;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    max-width: 800px;
    padding: 0px 0px 0px 0px;
    border-radius: 10px;
    overflow: hidden;
    z-index: 3;
    // filter: drop-shadow(0 0 50px rgba(5, 178, 246, 0.192));
    @media screen and (max-width: 390px) {
      max-width: 350px; // Decrease width below 380px
    }
    @media screen and (max-width: 380px) {
      max-width: 320px; // Decrease width below 380px
    }
    @media screen and (max-width: 350px) {
      max-width: 300px; // Decrease width below 380px
    }
    .closeBtn {
      height: 60px;
      padding: 0px 15px;
      cursor: pointer;
      font-size: x-large;
      transition: all 0.4s;
      background-color: rgb(0, 88, 146);
      color: aliceblue;
      @include flex(space-between, center, row, nowrap);

      > p {
        font-variant: small-caps;
        color: white;
      }

      .closeIcon {
        color: aliceblue;
        border-radius: 50%;
        padding: 2px;
        transition: all 0.3s;
        background-color: rgba(211, 0, 0, 0.781);
        color: aliceblue;
        &:hover {
          background-color: aliceblue;
          color: rgba(211, 0, 0, 0.781);
          transform: scale(1.1);
        }
      }
    }

    .row {
      @include flex(center, center, row, nowrap);
      @extend %selection;
      > img {
        max-width: 200px;
        margin: 50px;
        @extend %selection;
      }

      .col {
        height: 300px;
        margin: 50px;
        @extend %selection;
        @include flex(space-between, space-between, column, nowrap);

        > input {
          color: rgb(0, 0, 0);
          font-weight: 100;
          font-size: 1.3rem;
          // border: none;
          border-radius: 3px;
          height: 47px;
          width: 270px;
          margin: 10px 0px;
          background-color: white;
          // outline: none;
          padding-left: 15px;
          // box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.075);
          transition: 0.3s;
          border: 1px solid rgba(0, 0, 0, 0.753);
          &:focus {
            outline: 2px solid rgb(0, 101, 126);
            // box-shadow: 2px 2px 20px 1px rgba(0, 174, 255, 0.11);
            // border: 1px solid rgb(0, 101, 126);
          }

          &::placeholder {
            color: rgba(0, 0, 0, 0.725);
            font-weight: 100;
            opacity: 1;
          }
        }

        > .btn {
          font-size: 1.5rem;
          color: white;
          height: 60px;
          @include flex(center, center, row, nowrap);
          box-shadow: 2px 2px 20px 1px rgba(0, 140, 255, 0.171);
          border-radius: 5px;
          margin: auto 0px;
          cursor: pointer;
          transition: 0.5s;
          color: rgb(1, 87, 158);
          border: 1px solid rgb(1, 87, 158);
          position: relative;
          overflow: hidden;

          .btnContent {
            @include flex(center, center, row, nowrap);
            position: relative;
            z-index: 8;
            > p {
              margin-left: 10px;
              color: rgb(1, 92, 177);
            }

            > .icon {
              font-size: 20px;
            }
          }

          &:hover p {
            color: aliceblue;
          }

          &:hover .icon {
            color: aliceblue;
          }

          &:hover .left {
            border-top: 125px solid transparent;
            border-bottom: 125px solid transparent;
            border-left: 125px solid rgb(1, 92, 177);
          }

          &:hover .right {
            border-top: 125px solid transparent;
            border-bottom: 125px solid transparent;
            border-right: 125px solid rgb(1, 92, 177);
          }

          &:hover .top {
            border-left: 40px solid transparent;
            border-right: 40px solid transparent;
            border-top: 40px solid rgb(1, 92, 177);
          }

          &:hover .bottom {
            border-left: 40px solid transparent;
            border-right: 40px solid transparent;
            border-bottom: 40px solid rgb(1, 92, 177);
          }

          .right {
            right: 0%;
            position: absolute;
            width: 0;
            height: 0;
            border-top: 0px solid transparent;
            border-bottom: 0px solid transparent;
            border-right: 0px solid rgb(1, 92, 177);
            transition: all 0.5s;
          }

          .left {
            position: absolute;
            left: 0%;
            width: 0;
            height: 0;
            border-top: 0px solid transparent;
            border-bottom: 0px solid transparent;
            border-left: 0px solid rgb(1, 92, 177);
            transition: all 0.5s;
          }

          .top {
            position: absolute;
            top: 0%;
            width: 0;
            height: 0;
            border-left: 0px solid transparent;
            border-right: 0px solid transparent;
            border-top: 0px solid black;
            transition: all 1s;
          }

          .bottom {
            position: absolute;
            bottom: 0%;
            width: 0;
            height: 0;
            border-left: 0px solid transparent;
            border-right: 0px solid transparent;
            border-bottom: 0px solid black;
            transition: all 1s;
          }

          // &:hover {
          //   background-color: rgb(1, 87, 158);
          // }


          .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  width: fit-content;
  min-width: 150px;
}

.btn.loading {
  pointer-events: none;
  background-color: #0056b3;
}

.btn .icon {
  margin-right: 8px;
}

.loader {
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-radius: 50%;
  border-top: 2px solid transparent;
  animation: spin 0.8s linear infinite;
  margin-right: 8px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

        }
      }
    }
  }
}
/* Decrease max-width when screen width is below 380px */

@import "./OfflineDialogMediaquery.scss";
