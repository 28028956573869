@media only screen and (max-width: 1200px) {
  .titlebar {
    height: 300px;
  }
}

@media only screen and (max-width: 950px) {
  .titlebar {
    height: 150px;

    h1 {
      font-size: 16px;
      font-weight: 400;
    }

    #greenCircle-Left,
    #greenCircle-Right {
      width: 25px;
    }

    #orangeCircle-Left {
      width: 25px;
      top: 65px;
      left: 40px;
    }

    #orangeCircle-Right {
      width: 25px;
      top: 65px;
      right: 40px;
    }

    #outlineCircle-Left {
      top: 100px;
      left: 70px;
      width: 30px;
    }

    #outlineCircle-Right {
      top: 100px;
      right: 75px;
      width: 30px;
    }

    #greenV {
      top: 80px;
      right: 0;
      width: 25px;
    }
    #pinkV {
      right: 30px;
      bottom: 20px;
      width: 25px;
    }
  }
}
