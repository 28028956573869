@import "../../../Scss/ScssMixins.scss";
@import "PurchaseHistoryMobileStyle.scss";
@import "PurchaseHistoryResponsiveStyle.scss";

.purchaseHistory {
  padding-top: 30px;
  font-size: 1.6rem;

  .purchaseHistory-Back-Btn {
    margin: 40px 30px;
    width: 200px;
    font-size: 20px;
    @include flex(space-between, center, row, nowrap);
    cursor: pointer;
    &:hover {
      color: rgb(0, 65, 168);
    }
    .purchaseHistory-Back-Icon {
      font-size: 30px;
    }
  }

  .purchaseHistory-table {
    margin: auto;
    max-width: 1600px;
    height: 500px;
    // background-color: antiquewhite;

    .purchaseHistory-Table-Heading {
      border-top: 1px solid rgba(1, 162, 255, 0.534);
      border-bottom: 1px solid rgba(1, 162, 255, 0.534);
      @include flex(space-between, center, row, nowrap);
      h4 {
        width: 250px;
        text-align: center;
        color: rgb(0, 132, 255);
        font-weight: 500;
      }
    }

    .purchaseHistory-Table-Content {
      // border-top: 1px solid rgba(1, 162, 255, 0.534);
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.212);
      @include flex(space-between, center, row, nowrap);
      img {
        width: 50px;
        height: 50px;
      }
      h4 {
        width: 250px;
        text-align: center;
        color: rgb(92, 90, 90);
        font-weight: 500;
      }
    }
  }
}
