@import "../../../Scss//ScssMixins.scss";
$white: white;

.CoursesSidebar {
  color: $white;
  height: 100vh;
  position: fixed;
  top: 100px;
  width: 300px;
  background-color: rgb(1, 103, 146);
  overflow: hidden;
  font-size: 1.6rem;

  @include mediaquery-breakpoint(1100px) {
    display: none;
  }

  h2 {
    text-align: center;
  }

  div {
    &:nth-of-type(1) {
      @include flex(flex-start, flex-start, column, nowrap);

      .route {
        @include flex(center, center, row, nowrap);
        color: $white;
        text-decoration: none;
        border-radius: 6px;
        margin: 17px 20px;
        font-size: 20px;
        // width: 80%;
        position: relative;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;

        &:hover {
          color: rgb(252, 213, 165);
        }

        &:before {
          content: "";
          position: absolute;
          bottom: 0%;
          width: 100%;
          height: 1px;
          // background-color: aqua;
        }
      }

      .line {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.16);
        height: 1px;

        // border-bottom: 0.1px solid black;
      }
    }
  }
}
