@import "../../Scss/ScssMixins.scss";
@import "./main.scss";
@import "./Iconbar.scss";

%toolbelt {
  box-sizing: border-box;
  border-top: 1px rgba(#000, 0.12) solid;
  padding: 16px 0;
  width: 100%;

  &:hover {
    border: 2px rgba(#000, 0.5) solid;
  }
}

footer {
  font-size: 1.6rem;
  @include flex(center, center, row, nowrap);
  @include box(auto, 100%, rgb(2, 71, 122));
  @include main();
}

@include iconbar();
