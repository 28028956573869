.c-btn {
  background-color: #2b8ce7;
  width: fit-content;
  padding: 10px 40px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: 0.1s;
  margin: 10px;
  &:hover {
    background-color: #0b5fae;
  }
}
