/* mediaquery card mobile responsive */

@media only screen and (max-width: 400px) {
  .offlineCourses-title {
    // margin-top: 100px;
    font-size: 30px;
  }

  .offlineCourses {
    > .offlineCourses-card {
      margin: 20px;
      width: 90%;

      > .image {
        height: auto;
      }

      > .offlineCourses-content {
        > p {
          /* background-color: aqua; */
          font-size: medium;
        }
      }
    }
  }

  .offlineCourses
    > .offlineCourses-card
    > .offlineCourses-content
    > div:nth-of-type(1)
    > p {
    /* background-color: aqua; */
    font-size: small;
  }

  .offlineCourses
    > .offlineCourses-card
    > .offlineCourses-content
    > div:nth-of-type(1)
    > p
    > span
    > .offline-icons {
    font-size: large;
  }
}
