@import "../../Scss//ScssMixins.scss";

.contactUs {
  font-size: 1.6rem;
  width: 100%;
  background-color: whitesmoke;
  color: rgb(92, 90, 90);
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    > .div-left {
      margin-right: 200px;
      max-width: 700px;
      margin: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      > p {
        display: flex;
        align-items: center;
        max-width: 600px;

        > span {
          display: flex;
          align-items: center;
          margin-right: 20px;

          > .contactUs-icons {
            color: rgb(0, 174, 255);
            margin: 0%;
            font-size: 30px;
          }
        }
      }

      > iframe {
        border: 0px;
        max-width: 600px;
        height: 400px;
        margin-top: 20px;
      }
    }

    > .div-right {
      max-width: 700px;
      margin: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      > h3 {
        color: rgb(0, 174, 255);
      }

      > p {
        max-width: 500px;
      }

      > .textfield {
        display: flex;
        flex-direction: column;

        > p {
          > span {
            color: red;
          }
        }

        > input {
          background-color: rgba(255, 255, 255, 0.568);
          height: 40px;
          max-width: 500px;
          font-size: 15px;
          font-family: "Ubuntu", sans-serif;
          border-color: rgba(0, 0, 0, 0.349);
          border-width: 1px;
          border-radius: 5px;
          padding-left: 20px;
          font-weight: 100;

          &:focus {
            outline: rgb(0, 174, 255);
            border-color: rgb(0, 174, 255);
            box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.144);
          }

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        > input[type="number"] {
          -moz-appearance: textfield;
        }
      }

      .card {
        margin: 30px 0px;
        p {
          color: rgb(0, 93, 155);
          margin: 0%;
        }
        .calc {
          padding: 8px;
          height: 55px;
          width: 250px;
          background-color: rgba(128, 128, 128, 0.068);

          @include flex(space-between, center, row, nowrap);

          .box1 {
            height: 35px;
            width: 50px;
            background-color: rgba(0, 68, 146, 0.884);
            color: white;
            border-radius: 5px;
            @include flex(center, center, row, nowrap);
          }

          .box2 {
            height: 35px;
            width: 50px;
            background-color: rgba(0, 68, 146, 0.884);
            color: white;
            border-radius: 5px;
            @include flex(center, center, row, nowrap);
          }

          input[type="number"] {
            -moz-appearance: textfield;
          }

          input {
            height: 35px;
            width: 50px;
            border: 1px solid grey;
            color: rgb(92, 90, 90);
            padding-left: 15px;
            box-sizing: border-box;
            border-radius: 5px;
            @include flex(center, center, row, nowrap);
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            &:focus {
              outline-color: rgb(0, 174, 255);
            }
          }
        }
      }

      > .form-btn {
        height: 50px;
        width: 120px;
        background-color: rgb(1, 144, 210);
        box-sizing: border-box;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.5s;

        &:hover {
          transform: scale(1.05);
        }
        &:active {
          background-color: rgb(0, 124, 182);
          transform: scale(0.99);
        }

        &::selection {
          background-color: transparent;
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  /* .contactUs > div > .div-left > p > span > .contactUs-icons {
    display: block;
    background-color: aqua;
    color: black;
  } */
  .contactUs > div > .div-left {
    margin: 30px 50px 0px 50px;
    /* background-color: aqua; */
  }

  .contactUs > div > .div-left > iframe {
    height: 300px;
  }
}
