@mixin flex(
  $justify-content: flex-start,
  $align-items: flex-start,
  $flex-direction: row,
  $flex-wrap: nowrap
) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
  flex-direction: $flex-direction;
  flex-wrap: $flex-wrap;
}

@mixin box(
  $height,
  $width,
  $background-color: transparent,
  $borderRadius: auto
) {
  height: $height;
  width: $width;
  background-color: $background-color;
  border-radius: $borderRadius;
}

@mixin mediaquery-breakpoint($size) {
  @media (max-width: $size) {
    @content;
  }
}
