%TopbarCol2 {
  > .col2 {
    @include box(auto, 100%);
    max-width: 420px;
    position: absolute;
    top: 30%;
    right: 5%;

    @include mediaquery-breakpoint(1200px) {
      position: static;
      //   height: auto;
    }

    // @include mediaquery-breakpoint(500px) {
    //   margin: 30px auto;
    //   @include box(auto, 100%);
    // }

    > .card {
      @include flex(null, null, column);
      @include box(auto, auto, aliceblue);
      color: rgb(0, 93, 139);
      box-sizing: border-box;
      padding: 10px;
      border-radius: 10px;
      box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.301);
      margin: 50px auto;

      @include mediaquery-breakpoint(500px) {
      }

      .image {
        width: 100%;
        background-size: cover;

        // @include mediaquery-breakpoint(500px) {
        //   height: 200px;
        // }

        // @include mediaquery-breakpoint(400px) {
        //   height: 180px;
        // }

        // @include mediaquery-breakpoint(414px) {
        //   height: 180px;
        // }
      }

      .col {
        @include flex(null, null, column);
        padding-top: 30px;
        gap: 20px;
        box-sizing: border-box;

        .row {
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          .col1-content {
            @include flex(null, null, column);
            gap: 15px;

            > .col1-content-row {
              @include flex(null, center);
              gap: 20px;
              .icon {
                font-size: 25px;
              }

              .dropdown {
                background-color: aliceblue;
                color: rgb(0, 93, 139);
                border: none;
                border-bottom: 1px solid rgb(0, 93, 139);
                padding: 5px;
                font-size: 17px;

                &:focus {
                  outline: 1px solid rgb(0, 93, 139);
                }
              }
            }

            > .col2-content-row {
              @include flex(null, center);
              gap: 20px;
              .icon {
                font-size: 25px;
              }

              .dropdown {
                background-color: aliceblue;
                color: rgb(0, 93, 139);
                border: none;
                border-bottom: 1px solid rgb(0, 93, 139);
                padding: 5px;
                font-size: 17px;

                &:focus {
                  outline: 1px solid rgb(0, 93, 139);
                }
              }
            }

            > .col3-content-row {
              @include flex(null, center);
              gap: 20px;
              .icon {
                font-size: 30px;
              }

              .font {
                font-size: 18px;
              }
            }
          }

          .col2-content {
            justify-self: center;
            align-self: center;
            font-size: 30px;

            @include mediaquery-breakpoint(520px) {
              font-size: 20px;
            }
          }
        }

        > .purchaseBtn {
          @include box(45px, 100%, rgb(0, 93, 139));
          @include flex(center, center);
          margin-top: 10px;
          color: aliceblue;
          border-radius: 5px;
          cursor: pointer;
          overflow: hidden;

          // span {
          //   z-index: 3;
          //   transition: 0.2s;
          // }

          // &:hover::before {
          //   height: 800px;
          //   width: 800px;
          // }

          // &:hover span {
          //   color: aliceblue;
          // }

          // &::before {
          //   content: "";
          //   height: 0px;
          //   width: 0px;
          //   position: absolute;
          //   top: var(--y);
          //   left: var(--x);
          //   background-color: rgb(18, 136, 0);
          //   border-radius: 50%;
          //   transition: width 0.6s, height 0.6s;
          //   z-index: 2;
          //   transform: translate(-50%, -50%);
          // }
        }
      }
    }
  }
}
