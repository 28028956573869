@import "../../Scss/ScssMixins.scss";

.loginOTP {
  background-color: rgb(42, 157, 208);
  color: aliceblue;
  height: 100vh;
  @include flex(center, center, column, nowrap);

  .loginOTP-Card-Top {
    margin-top: 100px;
    padding: 20px;
    box-sizing: border-box;
    height: 460px;
    width: 500px;
    background-color: rgb(0, 103, 147);
    border-radius: 10px;
    text-align: center;
    @include flex(space-around, center, column, nowrap);

    > h1 {
      font-variant: small-caps;
      font-weight: 500;
    }

    .loginOTP-Card-Top-Textfield {
      width: 100%;
      @include flex(space-around, center, row, nowrap);

      input {
        font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
          sans-serif;
        box-sizing: border-box;
        color: white;
        background-color: transparent;
        // padding-left: 10px;
        margin: 40px 0px;
        height: 50px;
        width: 40px !important;
        // border-radius: 5px;
        border: none;
        font-size: 30px;
        border-bottom: 2px solid white;
        &:focus {
          outline: none;
        }
      }
    }

    .eye {
      font-size: 25px;
      cursor: pointer;
    }

    .loginOTP-Btn-Disable {
      height: 60px;
      width: calc(100% - 120px);
      margin: auto;
      background-color: rgba(87, 87, 87, 0.527);
      cursor: not-allowed;
    }

    .loginOTP-Btn {
      height: 60px;
      width: calc(100% - 120px);
      margin: auto;
      background-color: rgba(0, 72, 93, 0.815);
      @include flex(center, center, column, nowrap);
      font-size: large;
      cursor: pointer;
      position: relative;
      border-radius: 10px;
      transition: all 0.5s;

      p {
        position: absolute;
        z-index: 99;
        &::selection {
          background-color: transparent;
        }
      }

      &::before {
        content: "";
        // background-color: aliceblue;
        position: absolute;
        transition: all 0.5s;
        height: 100%;
        width: 0%;
      }

      &:hover::before {
        width: 100%;
        background-color: rgba(0, 60, 83, 0.822);
      }

      &:active {
        background-color: rgb(0, 67, 93);
        transform: scale(1.05);
      }

      &:hover {
        box-shadow: 1px 1px 100px 1px rgba(0, 189, 246, 0.335);
      }
    }

    .loginOTP-Card-backBtn {
      height: 60px;
      width: 60px;
      background-color: aliceblue;
      @include flex(center, center, column, nowrap);
      border-radius: 50%;
      cursor: pointer;

      .loginOTP-Card-backBtn-icon {
        font-size: x-large;
        color: rgb(1, 73, 101);
      }
    }
  }

  .loginOTP-Card-Bottom {
    margin-top: 15px;
    height: 70px;
    width: 500px;
    background-color: rgb(0, 103, 147);
    border-radius: 5px;
    @include flex(center, center, column, nowrap);
  }
}

@import "LoginotpMediaquery.scss";
