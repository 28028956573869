@import "../../Scss/ScssMixins.scss";

.sideNavbar {
  position: fixed;
  height: 100vh;
  right: 0px;
  top: 0;
  width: 0px;
  background-color: aliceblue;
  box-shadow: 2px 2px 50px 1px rgba(0, 0, 0, 0.233);
  transition: all 0.5s;
  z-index: 100;
}

.sideNavbar > div:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  background-color: rgb(0, 109, 160);
}

.sideNavbar > div:nth-child(1) > img {
  width: 180px;
  visibility: hidden;
  transition: all 0.1s;
}

.sideNavbar > div:nth-child(2) {
  background-color: aliceblue;
  height: 100%;
  box-sizing: border-box;
  padding: 20px 0px 10px 0px;
  transition: all 1s;
  display: none;
}

.sideNavbar > div:nth-child(2) > div > a {
  text-decoration: none;
  margin: 8px;
  display: block;
  color: rgb(92, 90, 90);
  font-size: small;
}

.sideNavbar > div:nth-child(2) > div {
  display: flex;
  align-items: center;
  margin-left: 25px;
  margin-bottom: 15px;
}

.sideNavbar > div:nth-child(2) > .sidebar-close {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20% 0px;
}

.sideNavbar > div:nth-child(2) > .sidebar-close > .sidebarIcon {
  position: fixed;
  bottom: 10%;
  font-size: 50px;
  color: rgba(251, 0, 0, 0.651);
  margin: auto auto 10px auto;
}

#mainCourses {
  margin-bottom: 10px;
  margin-right: 10px;
  text-decoration: none;
  @include flex(flex-start, center, row, nowrap);
  color: rgb(92, 90, 90);
  font-size: 14px;
  margin-bottom: 15px;
  font-weight: 400;
  cursor: pointer;

  > span {
    margin-right: 8px;
    @include flex(flex-start, center, row, nowrap);
    font-size: 15px;
  }
}

#course {
  margin-left: 40px;
  margin-bottom: 10px;
  text-decoration: none;
  @include flex(flex-start, center, row, nowrap);
  display: none;
  color: rgb(92, 90, 90);

  > span {
    margin-right: 8px;
    @include flex(flex-start, center, row, nowrap);
    font-size: 15px;
    margin-bottom: 10px;
  }
}

@media (min-width: 950px) {
  .sideNavbar {
    display: none;
  }
}
