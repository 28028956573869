@import "../../Scss/ScssMixins.scss";
@import "./_MentorCardStyle.scss";
@import "./_ContentStyle.scss";

.about {
  @include flex(center, center, column, nowrap);
  background-color: white;

  @extend %Content;
  @extend %Mentor;
}

// @import "_AboutUsResponsiveStyle.scss";
