@import "../../../Scss/ScssMixins.scss";

$blue: rgb(0, 90, 143);
$white: aliceblue;

.webinarForm {
  @include flex(flex-start, center, column, nowrap);
  overflow: hidden;
  position: relative;
  color: rgb(92, 90, 90);
  width: 100%;
  background-color: $white;
  padding: 30px 0px;

  .title {
    font-size: 30px;
    max-width: 900px;
    text-align: center;
    font-weight: 400;
    margin: 14px 10px;
    color: rgb(0, 90, 143);
    position: relative;
    z-index: 1;
  }

  .subTitle {
    max-width: 900px;
    margin: 0px 10px;
    font-size: 18px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .row {
    margin: 0px;
    @include flex(center, center, row, nowrap);

    @include mediaquery-breakpoint(1400px) {
      flex-wrap: wrap;
    }
    .left {
      position: relative;
      z-index: 1;
      max-width: 600px;
      background-color: white;
      margin: 50px;

      @include mediaquery-breakpoint(600px) {
        margin: 20px;
      }

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
      }

      p {
        text-align: center;
        font-size: 20px;
        // color: rgb(92, 90, 90);
      }
    }

    .right {
      position: relative;
      z-index: 1;
      margin: 50px;
      max-width: 450px;
      background-color: white;
      box-shadow: 2px 2px 50px 1px rgba(0, 0, 0, 0.192);

      @include mediaquery-breakpoint(600px) {
        margin: 20px;
      }

      .timer {
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
        background-color: whitesmoke;

        .time {
          text-align: center;
          color: rgb(0, 90, 143);
          @include flex(center, center, row, nowrap);
          span {
            margin: 0px 1px;
            font-size: 30px;
            width: 40px;
            // background-color: aqua;
          }
        }

        .day {
          width: 100%;
          text-align: center;
          @include flex(center, center, row, nowrap);
          span {
            color: $blue;
            margin: 0px 1px;
            font-size: 20px;
            width: 40px;
            // background-color: aqua;
          }
        }
      }

      p {
        text-align: center;
        &:nth-of-type(1) {
          padding: 10px;
          margin: 0%;
          background-color: $blue;
          color: $white;
        }
        &:nth-of-type(2) {
          font-size: 15px;
          padding: 10px;
        }
      }

      input {
        margin: 30px auto;
        display: block;
        width: calc(100% - 70px);
        height: 50px;
        box-sizing: border-box;
        background-color: whitesmoke;
        color: rgb(92, 90, 90);
        outline-color: transparent;
        border: 1px solid transparent;
        padding-left: 10px;
        font-size: 17px;
        font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
          sans-serif;
        border-radius: 5px;

        &:focus {
          box-shadow: 2px 2px 10px 1px rgba(0, 187, 255, 0.265);
        }
      }

      .btn {
        overflow: hidden;
        margin: 10px auto;
        background-color: $blue;
        height: 50px;
        color: $white;
        font-variant-caps: all-petite-caps;
        font-size: 22px;
        cursor: pointer;
        position: relative;
        @include flex(center, center, row, nowrap);

        span {
          position: relative;
          z-index: 1;
        }

        &:hover::before {
          left: 0%;
        }

        &::before {
          content: "";
          position: absolute;
          background-color: rgb(1, 133, 210);
          width: 100%;
          left: -100%;
          height: 100%;
          transition: all 0.5s;
        }
      }
    }
  }

  .circleLg {
    position: absolute;
    bottom: -250px;
    left: 10%;
    height: 300px;
    width: 80%;
    background-color: rgba(0, 197, 252, 0.157);
    box-shadow: 2px 2px 150px 1px rgba(0, 200, 255, 0.068);
    border-radius: 50%;
    z-index: 0;
    animation: MoveLg 10s;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    // &:hover {
    //   animation-play-state: paused;
    // }
  }

  .circle {
    top: 0px;
    right: 0px;
    position: absolute;
    height: 50px;
    width: 50px;
    background-color: rgba(0, 80, 252, 0.332);
    border-radius: 50%;
    // transition: all 1s;
    animation: MoveMD 20s infinite;
    animation-direction: alternate;

    &:hover {
      animation-play-state: paused;
    }
  }

  @keyframes MoveMD {
    0% {
      top: 0px;
      right: 0px;
    }
    20% {
      right: 500px;
      top: 100px;
    }
    40% {
      right: 600px;
      top: 200px;
    }

    60% {
      right: 600px;
      top: 200px;
    }
    80% {
      right: 800px;
      top: 500px;
    }
    100% {
      right: 900px;
      top: 300px;
    }
  }

  @keyframes MoveLg {
    from {
    }
    to {
      transform: scale(0.8);
    }
  }

  .square {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 50px;
    width: 50px;
    background-color: rgba(0, 153, 255, 0.61);
    // border-radius: 50%;
    animation: MoveSm 10s infinite;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }

  .triangle {
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 50px solid rgb(0, 247, 255);
    position: absolute;
    bottom: 0px;
    right: 0px;
    animation: Triangle 10s infinite;
    animation-direction: alternate-reverse;
  }

  @keyframes Triangle {
    0% {
      bottom: 10px;
      right: 10px;
    }

    25% {
      bottom: 200px;
      right: 200px;
    }

    50% {
      bottom: 300px;
      right: 300px;
    }

    75% {
      bottom: 500px;
      right: 500px;
    }

    100% {
      bottom: 800px;
      right: 600px;
    }
  }

  @keyframes MoveSm {
    0% {
      bottom: 0px;
      left: 0px;
    }
    20% {
      left: 500px;
      bottom: 100px;
    }
    40% {
      left: 600px;
      bottom: 200px;
    }

    60% {
      left: 600px;
      top: 200px;
    }
    80% {
      left: 800px;
      top: 500px;
    }
    100% {
      left: 900px;
      top: -300px;
    }
  }
}

// @import "../Style/ResponsiveWebinarForm.scss";
