@media only screen and (max-width: 1100px) {
  .appbarLeft {
    img {
      width: 250px;
    }
  }

  .appbarRight {
    display: none !important;
  }

  #loggedInAppbar-Profile {
    background-color: antiquewhite;
    display: none;
    .loggedInAppbar-Profile-Circle {
      visibility: hidden;
      background-color: antiquewhite;
    }
  }
  .loggedIn-Appbar-Menu {
    // visibility: visible;
    background-color: black;
    display: block !important;
    color: aliceblue;
  }
}

@media only screen and (max-width: 500px) {
  .appbarLeft {
    img {
      width: 200px;
    }
  }
}
