@import "./../../../Scss/ScssMixins.scss";

.notification-Dropdown {
  display: none;
  position: absolute;
  top: 85px;
  right: 30%;
  width: 350px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 2px 0px 0px 1px transparent;
  transition: all 1s;

  .arrow {
    width: 100%;
    @include flex(flex-end, center, row, nowrap);
    background-color: rgb(1, 145, 211);

    .triangle {
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 15px solid rgb(0, 255, 170);
      margin-right: 10px;
    }
  }

  .notification-titlebar {
    height: 50px;
    width: 100%;
    background-color: rgb(246, 244, 244);
    color: rgb(0, 40, 75);
    font-weight: 500;
    padding: 10px;
    box-sizing: border-box;
  }

  .notification-row {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: 1px solid grey;
    background-color: white;
    @include flex(space-around, center, row, nowrap);

    &:hover {
      background-color: aliceblue;
    }

    > img {
      background-color: aliceblue;
      width: 40px;
    }

    > .content {
      width: 240px;
      font-size: 15px;
      //   background-color: white;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .seeMoreBtn {
    height: 50px;
    width: 100%;
    background-color: rgb(0, 62, 116);
    color: white;
    @include flex(center, center, row, nowrap);
  }
}
