@import "../../Scss/ScssMixins.scss";

%decoration-none {
  text-decoration: none;
  list-style-type: none;
  color: rgba(0, 0, 0, 0.747);
}

.appbar {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 10px 0px rgba(9, 140, 241, 0.322);
  top: 0%;
  z-index: 99;
  width: 100%;
  height: 100px;
  // padding-block: 3rem;
  padding-inline: 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: sticky;
  font-size: 1.6rem;

  .tracking-line {
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 0%;
    height: 3px;
    background-color: rgb(0, 191, 249);

    @include mediaquery-breakpoint(600px) {
      display: none;
    }
  }
}

.appbar .center {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 240, 253);
}

.logoPortion {
  // margin: auto auto auto 100px;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoPortion > img {
  width: 250px;
}

.routingPortion {
  height: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.textDecoration {
  @include flex(center, center, row, nowrap);
  @extend %decoration-none;
  margin: 0px 20px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  height: 100%;
  border-bottom: 5px solid transparent;
  transition: all 0.2s;
  // background-color: aqua;

  &:hover {
    color: rgba(6, 176, 255, 0.993) !important;
  }

  &:hover div:nth-of-type(1) {
    background-color: rgb(250, 250, 250);
    display: flex;
    flex-direction: column;
    font-size: 16px;
    height: 80px;
    width: 160px;
  }

  div {
    &:nth-of-type(1) {
      border-top: 3px solid rgb(0, 82, 149);
      display: none;
      position: absolute;
      background-color: aqua;
      height: 0px;
      width: 0px;
      top: 100px;
      left: 0px;
      transition: all 1s;
      z-index: 1;
      box-sizing: border-box;
    }

    &::before {
      content: "";
      display: inline;
      top: -13px;
      left: 0px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 11px solid rgb(0, 82, 149);
      position: absolute;
    }

    .dropDownItem {
      height: 40px;
      background-color: aliceblue;
      color: rgb(14, 144, 207);
      box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.192);
      display: flex;
      align-items: center;
      text-decoration: none;
      padding-left: 10px;
      border-bottom: 1px solid rgb(0, 153, 241);
      transition: all 0.2s;
      &:hover {
        background-color: rgb(0, 59, 110);
        color: aliceblue !important;
      }
    }
  }
}

.loginBtn {
  overflow: hidden;
  height: 40px;
  width: 120px;
  border: 2px solid rgb(32, 151, 243);
  color: rgb(32, 151, 243);
  border-radius: 12px;
  margin: 0px 20px;
  cursor: pointer;
  transition: all 0.5s;
  box-shadow: 2px 2px 10px 1px white;
  position: relative;
  z-index: 0;

  &:hover > span {
    color: rgb(0, 108, 190);
    transition: all 0.1s;
  }

  &:hover::before,
  &:hover::after {
    width: 0%;
  }

  > span {
    z-index: 2;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0%;
    width: 50%;
    height: 100%;
    background-color: rgb(32, 151, 243);
    transition: all 0.2s;
    border-top-left-radius: 11px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0%;
    width: 50%;
    height: 100%;
    background-color: rgb(32, 151, 243);
    transition: all 0.2s;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 10px;
    z-index: 1;
  }
}

.menuBtn {
  display: none;
  height: 40px;
  width: 40px;
  background-color: transparent;
  cursor: pointer;
}

.loginBtn:hover {
  transform: scale(1.08);
}

@media only screen and (max-width: 1258px) {
  .appbar {
    height: auto;
    padding: 10px 10px 0px 10px;
    box-sizing: border-box;

    .logoPortion {
      padding: 15px 15px 15px 0px;
      margin: auto;
      width: 100%;
    }

    .routingPortion {
      margin: auto;
      #courses {
        height: 50px;
        div {
          &:nth-of-type(1) {
            top: 52px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .appbar {
    height: auto;
    padding: 14px;
    .logoPortion {
      padding: 15px;
      margin: auto auto auto 0px;
      width: auto;
      img {
        width: 200px;
      }
    }

    .routingPortion {
      margin: auto;
      display: none;
      flex-wrap: nowrap;
    }

    .menuBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto 20px auto auto;
    }
  }
}




