@import "../../../Scss//ScssMixins.scss";

.pageTitlebar {
  background-color: whitesmoke;
  box-shadow: 2px 2px 10px 1px rgba(0, 195, 255, 0.294);
  color: rgb(0, 133, 250);
  height: 60px;
  top: 0%;
  @include flex(center, center, row, nowrap);
  font-size: 1.6rem;
  font-weight: 600;
  z-index: 99;
}

@media only screen and (max-width: 1100px) {
  .pageTitlebar {
    width: 100%;
  }
}
