@import "../../../Scss/ScssMixins.scss";

%btn {
  @include flex(center, center, row, nowrap);
  height: 30px;
  width: 100px;
  border-radius: 5px;
  margin: 0px 6px;
  // color: rgb(0, 115, 215);
  color: #09145d;
  font-size: 15px;
  background-color: aliceblue;
  cursor: pointer;
}

.webinarAlert {
  min-height: 50px;
  // background-color: #0b730f;
  // background-color: #d34b09;
  background-color: #09145d;
  padding: 10px 0px;
  box-sizing: border-box;
  color: aliceblue;
  text-align: center;
  position: relative;
  top: 0px;
  @include flex(center, center, row, wrap);

  .center {
    @include flex(center, center, row, wrap);
    margin: auto;
    .time {
      span {
        margin: 0px 10px;
        font-size: 20px;
      }
    }

    .courseTitle {
      margin: 0px 10px;
      span {
        // font-variant-caps: all-petite-caps;
        font-size: 20px;
        margin: 0px 5px;
        font-weight: 500;
      }
    }

    .join-Btn {
      @extend %btn;
    }

    .upcomimg-Btn {
      @extend %btn;
    }
  }

  .close {
    margin-right: 10px;
    font-size: 30px;
    @include flex(center, center, row, wrap);
    cursor: pointer;
    &:hover {
      color: rgb(192, 34, 34);
    }
  }
}
