@import "./TopbarStyle.scss";

.allCourses-viewDetails {
  font-size: 1.6rem;
  @extend %Topbar;
}

.viewDetails-center {
  display: flex;
  justify-content: center;
}

.courseView-maxWidth {
  width: 1600px;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  position: relative;
}

/* CARD CSS CLOSE */

/* VIEWDETAILS-TOPBAR  MEDIAQUERY */

@media only screen and (max-width: 1200px) {
  .topbar-Card {
    position: relative;
    flex-wrap: wrap-reverse;
  }

  .courseView-maxWidth {
    justify-content: center;
  }

  .topbar-Content-Back-Btn {
    display: none;
  }

  .topbar-Content {
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 500px;
  }

  .topbar-Content > h1 {
    font-size: x-large;
  }

  .courseView-maxWidth {
    justify-content: flex-start;
    max-width: 450px;
  }

  .viewDetails-topbar {
    padding-top: 80px;
  }
}

/* MOBILE VIEWDETAILS-TOPBAR  MEDIAQUERY */

@media only screen and (max-width: 600px) {
  .viewDetails-topbar {
    position: relative;
    top: -20px;
  }

  .topbar-Card-image {
    width: 300px;
    height: 155px;
  }

  .topbar-Card-date-col > p {
    font-size: medium;
  }

  .topbar-Content {
    width: 320px;
    display: flex;
    justify-content: center;
  }

  .courseView-maxWidth {
    justify-content: center;
  }
}

/* VIEWDETAILS CONTENT CSS OPEN*/

.viewDetails-contents {
  padding: 40px;
  display: flex;
  justify-content: center;
}

.viewDetails-contents > h2:nth-of-type(2) {
  text-align: center;
}

.viewDetails-maxwidth {
  /* background-color: antiquewhite; */
  width: 1600px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* max-width: 800px; */
}

.viewDetails-Details {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px 0px;
}

.viewDetails-Details:hover {
  background-color: rgba(0, 134, 251, 0.308);
}

.viewDetails-Details-row {
  display: flex;
  justify-content: space-between;
}

.viewDetails-Details-row-content > p {
  display: block;
  transition: display 2s;
  margin: 0%;
  padding: 8px 10px;
  background-color: rgb(233, 241, 250);
}

.viewDetails-Details-row > p:nth-child(1),
.viewDetails-Details-row > p:nth-child(2) {
  margin: 10px 20px;
  display: flex;
  align-items: center;
}

.course-details {
  max-width: 800px;
  margin-right: 500px;
}

/* VIEWDETAILS CONTENT CSS CLOSE*/

/* MOBILE VIEWDETAILS-CONTENT  MEDIAQUERY */

@media only screen and (max-width: 1200px) {
  .viewDetails-maxwidth {
    align-items: center;
  }
  .course-details {
    margin-right: 0px;
    text-align: justify;
    /* background-color: antiquewhite; */
  }

  .viewDetails-contents {
    padding-top: 8px;
  }
}
