@mixin main {
  main {
    max-width: 1200px;
    width: 95%;
    display: grid;
    grid-template-columns: 2fr repeat(3, 1fr);
    gap: 10px;
    grid-column-gap: 20px;
    color: aliceblue;
    margin-block: 50px;

    @include mediaquery-breakpoint(1025px) {
      grid-template-columns: 2fr 1fr;
      gap: 50px;
    }

    @include mediaquery-breakpoint(850px) {
      grid-template-columns: 1fr;
    }

    .item1 {
      h1 {
        margin-top: 0%;
        font-size: 30px;
      }
      p {
        margin: 20px 20px 30px 0px;
        line-height: 30px;
      }

      .row {
        @include flex(flex-start, center, row, wrap);
        gap: 10px;

        @include mediaquery-breakpoint(500px) {
          width: 100%;
          font-size: 15px;
          justify-content: center;
          gap: 10px;
        }
        input {
          padding-left: 10px;
          @include box(45px, 230px, whitesmoke, 5px);

          @include mediaquery-breakpoint(850px) {
            @include box(45px, 180px, whitesmoke, 5px);
          }
        }

        .subcriptionBtn {
          float: right;
          color: rgb(0, 174, 255);
          cursor: pointer;
          @include box(45px, 120px, whitesmoke, 5px);
          @include flex(center, center, row, nowrap);

          @include mediaquery-breakpoint(850px) {
            @include box(45px, 90px, whitesmoke, 5px);
          }
        }
      }
    }

    @for $i from 2 through 4 {
      .item#{$i} {
        @include flex(flex-start, center, column, nowrap);

        p {
          position: relative;
          width: max-content;
          margin: 10px auto;

          cursor: pointer;
          &::before {
            content: "";
            position: absolute;
            width: 0%;
            height: 1px;
            background-color: rgb(0, 225, 255);
            left: 50%;
            bottom: -5px;
            transform: translate(-50%);
            transition: all 0.4s;
          }

          &:hover::before {
            width: 100%;
          }
        }
      }
    }
  }
}
