main {
  // cursor: none !important;
  .dashboard {
    width: 100%;
  }
}

.cursor {
  width: 40px;
  height: 40px;
  // background-color: black;
  // border: 2px solid rgb(13, 0, 86);
  background-color: rgba(0, 255, 13, 0.19);
  filter: blur(5px);
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1000;
  transition: all 0.1s;
  pointer-events: none;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: rgb(0, 255, 13);
  // border: 2px solid rgb(255, 0, 0);
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 9999;
  transition: all 0s;
  pointer-events: none;
}
