@media only screen and (max-width: 900px) {
  .webinarMentor {
    .webinarHeading {
      font-size: 30px;
      margin: 0px;
    }

    .webinarMentorRow {
      p {
        font-size: 16px;
      }
    }
  }
}
