@import "../../../Scss/ScssMixins.scss";

.offlineCourses-title {
  color: rgb(0, 116, 170);
  text-align: center;
  font-weight: 300;
  font-size: 40px;
  font-variant: small-caps;
}

.offlineCourses {
  height: 100%;
  width: 100%;
  color: rgb(92, 90, 90);
  font-size: 1.6rem;
  @include flex(center, center, row, wrap);

  > .offlineCourses-card {
    max-width: 320px;
    background-color: white;
    border-radius: 15px;
    margin: 50px 40px;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.13);
    transition: all 0.2s;

    &:hover {
      box-shadow: 2px 2px 30px 1px rgba(0, 137, 250, 0.246);
    }

    > .image {
      @include flex(center, flex-start, row, wrap);
      width: 100%;
      height: 180px;
      background-color: rgba(0, 115, 168, 0.842);
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 15px;

      > img {
        width: 100%;
        // object-fit: cover;
        border-radius: 15px;
      }
    }

    > .offlineCourses-content {
      padding: 15px;
      box-sizing: border-box;

      > .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 17px;
        margin: 10px 0px;
        font-weight: 400;
        max-width: 100%;
        color: rgb(0, 73, 147);
      }

      > .subTitle {
        font-size: 15px;
        margin: 10px 0px;
        font-weight: 400;
        max-width: 100%;
        text-overflow: ellipsis;
        max-lines: 2;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    > .offlineCourses-View-Btn {
      @include flex(center, center, row, nowrap);
      width: 90%;
      height: 45px;
      color: rgb(0, 174, 255);
      font-weight: 500;
      border: 1px solid rgb(0, 174, 255);
      border-style: inset;
      cursor: pointer;
      position: relative;
      margin: 0px auto 15px auto;
      position: relative;

      > p {
        z-index: 1;
        transition: all 1s;
      }

      > div {
        position: absolute;
        height: 100%;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        transition: all 2s;
      }
    }
  }
}

.offlineCourses-View-Btn:hover > p {
  color: white;
}

.offlineCourses-View-Btn::before {
  content: "";
  position: absolute;
  height: 0%;
  width: 100%;
  bottom: 0%;
  background-color: rgba(0, 82, 149, 0.013);
  transition: height 0.5s, width 0.7s, background-color 1s;
}

.offlineCourses-View-Btn:hover::before {
  height: 100%;
  color: black;
  background-color: rgb(0, 82, 149);
}

@import "./OfflineCoursesResponsive.scss";
