%Content {
  .content {
    padding: 50px;
    max-width: 1800px;
    box-sizing: border-box;
    @include flex(center, center, row, nowrap);

    @include mediaquery-breakpoint(1400px) {
      flex-wrap: wrap;
    }

    @include mediaquery-breakpoint(600px) {
      padding: 20px 0px;
    }

    .left-content {
      margin: 0px 50px;

      @include mediaquery-breakpoint(800px) {
        margin: 0px 20px;
      }

      @include mediaquery-breakpoint(600px) {
        margin: 0px 25px;
      }

      > h2 {
        color: rgb(0, 162, 255);

        @include mediaquery-breakpoint(600px) {
          text-align: center;
          font-size: 20px;
          font-weight: 500;
        }
      }

      > p {
        max-width: 850px;
        text-align: justify;
        font-size: 17px;
        line-height: 1.7;
      }
    }

    .right-content {
      @include flex(center, center, row, nowrap);
      margin: 20px 50px;

      > img {
        width: 620px;

        @include mediaquery-breakpoint(1650px) {
          width: 600px;
        }

        @include mediaquery-breakpoint(1600px) {
          width: 450px;
        }

        @include mediaquery-breakpoint(1600px) {
          width: 100%;
        }
      }
    }
  }
}
