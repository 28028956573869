@import "../../Scss/ScssMixins.scss";
@import "./LoginShapes.scss";

.login {
  position: relative;
  background-color: rgb(42, 157, 208);
  color: aliceblue;
  min-height: calc(100vh - 100px);
  font-size: 1.6rem;
  @include flex(center, center, column, nowrap);

  @include mediaquery-breakpoint(500px) {
    background-color: rgb(0, 103, 147);
  }

  .login-Card-Top {
    height: 450px;
    width: 500px;
    background-color: rgb(0, 103, 147);
    border-radius: 10px;
    text-align: center;
    z-index: 1;
    @include flex(space-around, center, column, nowrap);

    > h1 {
      font-variant: small-caps;
    }

    @include mediaquery-breakpoint(500px) {
      height: auto;
      width: 100%;
    }

    .login-Card-Top-Textfield {
      width: 100%;
      @include flex(space-around, center, row, nowrap);

      @include mediaquery-breakpoint(500px) {
        flex-direction: column;
      }

      .country-Dropdown {
        // font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        //   sans-serif;
        font-size: larger;
        color: rgb(92, 90, 90);
        font-weight: 200;
        height: 50px;
        width: 120px;
        margin: 40px auto 40px 50px;

        &:focus {
          outline: none;
        }

        @include mediaquery-breakpoint(500px) {
          width: 85%;
          margin: 20px auto;
          font-weight: 300;
          font-size: 16px;
        }
      }

      input {
        // font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        //   sans-serif;
        font-weight: 500;
        box-sizing: border-box;
        color: rgb(92, 90, 90);
        padding-left: 10px;
        margin: 40px 50px 40px auto;
        height: 50px;
        width: 250px;
        border-radius: 5px;
        border: none;
        font-size: larger;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          display: none;
        }

        &:nth-of-type(2) {
          width: 280px;
          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
          }
        }
        &:focus {
          outline: none;
        }

        @include mediaquery-breakpoint(500px) {
          width: 85%;
          margin: 20px auto;
          font-weight: 300;
          font-size: 16px;
        }
      }
    }

    .login-Card-Top-nextBtn {
      @include flex(center, center, column, nowrap);
      height: 50px;
      width: calc(100% - 100px);
      font-size: large;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      color: aliceblue;
      background-color: rgb(0, 64, 104);
      z-index: 1;

      span {
        font-weight: 500;
        z-index: 3;
      }

      &::before {
        content: "";
        height: 100%;
        width: 0%;
        left: 0%;
        position: absolute;
        background-color: rgb(12, 125, 196);
        transition: all 0.5s;
        z-index: 2;
      }

      &:hover::before {
        width: 100%;
      }

      &::selection {
        background-color: transparent;
      }

      @include mediaquery-breakpoint(500px) {
        width: 85%;
        margin: 20px auto;
      }
    }

    p {
      width: 85%;
      margin: 20px auto;
      color: antiquewhite;
    }
  }

  .login-Card-Bottom {
    z-index: 1;
    margin-top: 10px;
    height: 70px;
    width: 500px;
    background-color: rgb(0, 103, 147);
    border-radius: 5px;
    @include flex(center, center, column, nowrap);
    > p > a {
      text-decoration: none;
      color: aqua;
    }
  }

  // #recaptcha-container {
  //   position: absolute;
  //   top: 90%;
  //   left: 50%;
  //   // width: 500px;
  //   // height: 700px;
  //   transform: translate(-50%, -40%);
  //   background-color: aliceblue;
  //   z-index: 5;
  // }
}

@keyframes Rotate {
  to {
    transform: rotate(0deg);
  }

  from {
    transform: rotate(360deg);
  }
}

// @import "LoginMediaquery.scss";
