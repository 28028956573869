@import "../../Scss//ScssMixins.scss";

.services {
  width: 100%;
  background-color: white;
  overflow: hidden;
  font-size: 1.6rem;

  > h1 {
    text-align: center;
    color: rgb(0, 174, 255);
    font-weight: 500;
  }

  .row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 410px;
      text-align: center;
      padding: 30px;
      background-color: whitesmoke;
      margin: 30px;
      text-align: center;
      transition: all 0.2s;

      &:hover {
        box-shadow: 5px 5px 20px 1px rgba(0, 136, 255, 0.171);
        cursor: pointer;
      }

      > h3 {
        color: rgb(0, 174, 255);
      }
    }
  }

  > .description-row {
    @include flex(center, flex-start, row, nowrap);
    margin: 50px;

    &::nth-child(2) {
      flex-wrap: wrap-reverse;
    }

    > div {
      max-width: 800px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 50px 60px;
      text-align: center;
      // background-color: antiquewhite;

      > h3 {
        color: rgb(0, 174, 255);
      }

      > p {
        text-align: justify;
        max-width: 800px;
      }
    }

    > img {
      margin: 10px;
    }
  }
}

@import "./ResponsiveStyle.scss";
