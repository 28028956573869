@media only screen and (max-width: 1650px) {
  .services {
    .row {
      .card {
        max-width: 380px;
        > img {
          max-width: 150px;
        }
      }
    }
    .description-row {
      margin: 10px;
      text-align: center;
      @include flex(space-around, center, row, nowrap);
      > img {
        max-width: 330px;
      }

      > div {
        // margin: 10px;
        display: flex;
        justify-content: center;

        > h3 {
          font-size: 15px;
          max-width: 360px;
        }

        > p {
          text-align: justify;
          max-width: 800px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1550px) {
  .services {
    .row {
      .card {
        padding: 20px;
        box-sizing: border-box;
        margin: 20px 25px;
        // background-color: aqua;
        max-width: 350px;
        > img {
          max-width: 150px;
        }

        h3 {
          max-width: 340px;
        }

        // > p {
        //   text-align: justify;
        //   max-width: 360px;
        // }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .services {
    .row {
      .card {
        max-width: 380px;
        > img {
          max-width: 150px;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .services {
    > h1 {
      margin: 30px;
      font-size: larger;
    }

    .description-row {
      margin: 10px;
      text-align: center;
      @include flex(center, center, column, nowrap);
      > img {
        max-width: 300px;
      }

      > div {
        margin: 10px;
        display: flex;
        justify-content: center;

        > h3 {
          font-size: 15px;
        }

        > p {
          text-align: justify;
          max-width: 350px;
        }
      }
    }

    #description {
      order: 2;
    }

    #img {
      order: 1;
      max-width: 250px;
    }
  }
}
