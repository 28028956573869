@import "../../../Scss/ScssMixins.scss";
@import "./TopbarStyle.scss";

.OnlineViewDetail {
  font-size: 1.6rem;
  @extend %Topbar;
}
/* Skeleton Loader Styling */
.skeleton-loader {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  background: #e0e0e0;
  border-radius: 8px;
  animation: shimmer 1.5s infinite linear;
}

.skeleton-loader .skeleton-heading,
.skeleton-loader .skeleton-paragraph,
.skeleton-loader .skeleton-image,
.skeleton-loader .skeleton-details,
.skeleton-loader .skeleton-button {
  background: #e0e0e0;
  border-radius: 8px;
  animation: shimmer 1.5s infinite linear;
}

.skeleton-loader .skeleton-heading {
  width: 60%;
  height: 24px;
}

.skeleton-loader .skeleton-paragraph {
  width: 100%;
  height: 16px;
}

.skeleton-loader .skeleton-image {
  width: 100%;
  height: 200px;
  border-radius: 12px;
}

.skeleton-loader .skeleton-details {
  width: 80%;
  height: 18px;
}

.skeleton-loader .skeleton-button {
  width: 30%;
  height: 40px;
  margin-top: 20px;
}

@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

