@media only screen and (max-width: 1128px) {
  .coverImg {
    .bgClr {
      .row {
        margin-block: 30px;
        justify-content: center;
        flex-wrap: wrap-reverse;
        text-align: center;
      }
    }
  }
}
