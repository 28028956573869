@import "../../../Scss/ScssMixins.scss";

.myCoursesMoreInfo {
  font-size: 1.6rem;
  .myCourse-Topbar {
    color: white;
    background-color: rgb(0, 70, 105);
    padding: 50px;
    @include flex(center, flex-start, row, nowrap);

    .maxWidth {
      width: 1600px;

      .main-icon {
        font-size: 18px;
        width: 180px;
        cursor: pointer;

        &:hover {
          color: rgb(0, 174, 255);
        }
        @include flex(flex-start, center, row, nowrap);
        .icon {
          font-size: 40px;
        }
      }

      > h1 {
        font-variant: small-caps;
      }
      .myCourse-Third-Row {
        max-width: 270px;
        @include flex(space-between, center, row, nowrap);
        p {
          @include flex(space-between, center, row, nowrap);
          span {
            @include flex(center, center, row, nowrap);
            margin-right: 10px;
          }
        }
      }
    }
  }
}
