.q-div {
  border-bottom: 2px solid rgb(214, 214, 214);
  padding-bottom: 20px;
  margin: 15px;
  .q-content {
    margin-left: 20px;
    .q-des {
      border: 2px solid rgb(222, 222, 222);
      width: fit-content;
      padding: 10px;
      border-radius: 8px;
    }
    .option-div {
      margin: 10px;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      .option {
        width: fit-content;
        padding: 5px 15px;
        cursor: pointer;
        display: block;
        margin: 5px;
        border-radius: 5px;
        background-color: rgb(236, 236, 236);
      }
      .op-selected {
        background-color: rgb(189, 225, 255);
        color: rgb(21, 104, 188);
      }
      .ans_item {
        background-color: rgb(214, 250, 222);
        color: rgb(13, 135, 31);
      }
    }
    .ans {
      .c-ans {
        color: green;
        font-weight: bold;
      }
      .w-ans {
        color: red;
        font-weight: bold;
      }
      .s-ans {
        color: gray;
        margin-left: 10px;
      }
    }
  }
}
