.backdrop {
  height: 100vh;
  width: 100vw;
  background-color: #000000b8;
  position: fixed;
  top: 0;
  left: 0;
  bottom: auto;
  right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal {
    height: 400px;
    width: 400px;
    background-color: aqua;
  }
}
