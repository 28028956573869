@import "../../Scss//ScssMixins.scss";

$circleValue: 50px;
$positionValue: 20px;
.f-a-b {
  position: fixed;
  bottom: $positionValue;
  right: $positionValue;
  z-index: 999;
  .fab-name {
    position: relative;
    .tagname {
      position: absolute;
      width: 0px;
      height: 35px;
      // left: 0%;
      // left: 90%;
      top: 50%;
      transform: translateY(-50%);
      background: white;
      // padding-right: 20px;
      z-index: -5;
      // padding: 5px 10px;
      // padding-right: 25px;
      transition: 0.3s;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 5px;
      span {
        margin-left: 10px;
        color: #0088ff;
      }
    }

    &:hover {
      .tagname {
        right: 80%;
        width: 180px;
        // white-space: nowrap;
      }
    }
    .floatingActionButton {
      @include flex(center, center, row, nowrap);
      // position: relative;
      height: $circleValue;
      width: $circleValue;
      z-index: 0;
      background-color: #0088ff;
      // background-color: #289133;
      box-shadow: 2px 2px 80px 1px rgba(0, 0, 0, 0.788);
      border-radius: 50%;
      text-decoration: none;
      margin-bottom: 10px;

      .icon {
        font-size: 25px;
        color: aliceblue;
      }
    }
  }
}
.c-h-b {
  height: 50px;
  width: 50px;
  position: fixed;
  transition: 0.3s;
  bottom: 10px;
  right: $positionValue;
  background-color: red;

  z-index: 99999;
  .closeIcon {
    height: 80px;
    width: 100%;
    position: absolute;
    z-index: 99;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
  }
  .cb {
    position: relative;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
  }
}
.c-h-b-open {
  height: 450px;
  width: 350px;
}
