.submit-alert-div {
  width: 420px;
  background-color: #e8f5fc;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
  .ico {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 35px;
    color: #8ec6f0;
    cursor: pointer;
  }
  .result-content {
    width: 100%;
    .collection-name {
      font-size: 30px;
      color: #1b9be2;
      margin: 0;
    }
    .topic-name {
      font-size: 20px;
      color: #86b3ce;
      margin-top: -5px;
    }
    .progress {
      position: relative;
      width: 100%;
      // height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 15px;
      .result-info {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .status {
          display: flex;

          .correct {
            font-size: 18px;
            margin-right: 10px;
            &::before {
              content: "Correct : ";
              color: rgb(23, 152, 23);
            }
          }
          .wrong {
            font-size: 18px;
            margin-right: 10px;
            &::before {
              content: "Wrong : ";
              color: rgb(244, 69, 63);
            }
          }
          .skiped {
            font-size: 18px;
            margin-right: 10px;
            &::before {
              content: "Skiped : ";
              color: rgb(121, 121, 121);
            }
          }
        }
        span {
          margin: 0 0 5px 0;
          font-size: 20px;
          color: #48b5f4;
        }
      }

      .bg-prog {
        width: 100%;
        height: 15px;
        border-radius: 50px;
        background-color: #c2e3fb;
        overflow: hidden;

        .prog {
          width: 50%;
          height: 100%;
          background-color: #48b5f4;
          border-radius: 50px;
        }
      }
    }
    .info {
      margin-top: 15px;
      font-size: 18px;
      color: #339ad6;
    }
    .input {
      margin-top: 20px;
      width: 100%;
      height: 50px;
      border: 2px solid #48b5f4;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      input {
        height: 100%;
        width: 100%;
        background-color: transparent;
        box-sizing: border-box;
        outline: none;
        border: none;
        font-size: 19px;
        padding: 0px 10px;
        color: #1187c7;
      }
      ::placeholder {
        color: #92bed8;
      }
    }
    .submit-btn {
      height: 50px;
      width: 100%;
      background-color: #1975ca;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 20px;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.1s;
      margin-top: 20px;
    }
  }
}
