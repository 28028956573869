@import "../../../Scss/ScssMixins.scss";
@import "./UpcomingCoursesDesktopCardStyle.scss";
@import "./UpcomingCoursesMobileCardStyle.scss";

%icon {
  .icon {
    font-size: 35px;
    cursor: pointer;
    height: 50px;
    width: 50px;
    transition: all 0.5s;
    margin: 40px;

    &:hover {
      color: rgb(0, 162, 255);
    }
  }
}

.upcomingCourses {
  font-size: 1.6rem;
  overflow: hidden;
  width: 100%;
  background-color: rgb(255, 255, 255);
  @include flex(flex-start, center, column, nowrap);

  > h1 {
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: rgb(0, 174, 255);
    font-weight: 300;
  }

  > p {
    text-align: center;
    max-width: 500px;
    color: rgb(92, 90, 90);
    font-weight: 300;
    max-width: 860px;
  }

  .row {
    @include flex(center, center, row, nowrap);
    .leftIcon {
      @extend %icon;
    }

    .rightIcon {
      @extend %icon;
    }

    .slider {
      scroll-behavior: smooth;
      height: 500px;
      max-width: 1200px;
      margin: 20px auto;
      overflow-y: hidden;
      overflow-x: scroll;
      @include flex(space-between, center, row, nowrap);
      position: relative;

      .loading {
        width: 1200px;
      }

      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      .slide {
        @include flex(space-between, center, row, nowrap);
        height: 100%;
        width: 100%;
        @extend %desktopCard;
      }
    }
  }

  .mobile-Slider {
    display: none;
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    // background-color: aqua;
    padding: 15px 0px;

    .loading {
      width: 100%;
    }

    &::-webkit-scrollbar:horizontal {
      height: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 10px solid rgb(0, 204, 255);
      /* should match background, can't be transparent */
      background-color: rgba(0, 234, 255, 0.5);
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    .mobile-Slides {
      width: 100%;
      background-color: aliceblue;
      @extend %mobileCard;
    }
  }

  .mobile-Row {
    display: none;
    width: 100%;
    height: 50px;

    .leftIcon {
      font-size: 40px;
      margin: auto 20px auto auto;
    }

    .rightIcon {
      font-size: 40px;
      margin: auto auto auto 20px;
    }
  }
}

@import "./ResponsiveUpcomingCourses.scss";
