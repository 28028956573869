@import "../../../Scss/ScssMixins.scss";

.loggedInAppbar-Dropdown {
  display: none;
  position: absolute;
  top: 100px;
  right: 0%;
  height: 0px;
  width: 280px;
  background-color: whitesmoke;
  color: black;
  box-shadow: 2px 2px 50px 1px rgba(0, 0, 255, 0.226);
  transition: all 1s;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .loggedInAppbar-Dropdown-Row {
    width: 100%;
    @include flex(center, center, row, nowrap);
    padding: 20px 0;
    box-sizing: border-box;
    background-color: rgb(209, 217, 223);

    //left
    .loggedInAppbar-Dropdown-Left-Circle {
      height: 50px;
      width: 50px;
      min-width: 50px;
      border-radius: 50%;
      background-color: rgb(0, 55, 80);
      margin: auto;
      @include flex(center, center, row, nowrap);
      color: aliceblue;
    }

    //right
    .loggedInAppbar-Dropdown-Right {
      width: calc(100% - 70px);
      // min-width: 150px;

      p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin: 0%;
        &:nth-of-type(1) {
          font-size: large;
          color: rgb(0, 140, 255);
          font-weight: 500;
        }
        &:nth-of-type(2) {
          font-size: small;
          color: rgb(31, 32, 34);
        }
      }
    }
  }

  .loggedInAppbar-Dropdown-Col {
    padding-top: 20px;
    box-sizing: border-box;
    @include flex(center, flex-start, column, nowrap);
    gap: 5px;

    .loggedInAppbar-Dropdown-Col-li {
      padding: 10px;
      box-sizing: border-box;
      //   background-color: antiquewhite;
      width: 100%;
      @include flex(flex-start, center, row, nowrap);
      border: 1px solid transparent;
      text-decoration: none;
      color: rgb(92, 90, 90);

      &:hover {
        border: 1px inset rgb(0, 89, 255);
      }

      &:hover .text-decoration {
        color: rgb(0, 81, 255);
      }

      &:hover .loggedInAppbar-Dropdown-Col-li-icons {
        color: rgb(0, 81, 255);
      }

      .loggedInAppbar-Dropdown-Col-li-icons {
        margin-right: 10px;
      }
    }
  }
}
