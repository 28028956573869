.journey {
  background-color: aliceblue;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
  font-size: 1.5rem;

  h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: rgb(0, 174, 255);
  }

  > p {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    color: rgb(105, 105, 105);
    max-width: 800px;
    text-align: center;
  }

  .contentBox {
    max-width: 1200px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 35px auto;

    > div {
      > p {
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        color: rgb(92, 90, 90);
        max-width: 800px;
        text-align: center;
      }

      &:nth-child(1) {
        /* background-color: white; */
        height: 300px;
        max-width: 350px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px;
        /* box-shadow: 2px 2px 10px rgba(245, 222, 179, 0.767); */

        > h2 {
          color: rgb(0, 174, 255);
          font-family: "Poppins", sans-serif;
          font-weight: 400;
        }
      }

      &:nth-child(2) {
        /* background-color: white; */
        height: 300px;
        max-width: 350px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px;
        /* box-shadow: 2px 2px 10px rgba(245, 222, 179, 0.767); */
        > h2 {
          color: rgb(0, 174, 255);
          font-weight: 400;
        }
      }

      &:nth-child(3) {
        height: 300px;
        max-width: 350px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px;
        /* box-shadow: 2px 2px 10px rgba(245, 222, 179, 0.767); */
        > h2 {
          color: rgb(0, 174, 255);
          font-weight: 400;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .journey > h1 {
    text-align: center;
    font-size: larger;
    font-weight: 500;
    /* background-color: aliceblue; */
    box-shadow: 2px 2px 20px 1px white;
  }

  .contentBox > div:nth-child(1) > h2 {
    font-size: large;
  }

  .contentBox > div:nth-child(2) > h2 {
    font-size: large;
  }

  .contentBox > div:nth-child(3) > h2 {
    font-size: large;
  }
}
