@import "../../../Scss/ScssMixins.scss";

.zoomSchedule {
  width: 100%;
  overflow: hidden;
  margin-left: auto;
  width: calc(100% - 300px);
  font-size: 1.6rem;

  @include mediaquery-breakpoint(1100px) {
    width: 100%;
  }

  .zoomSchedule-Header {
    position: fixed;
    background-color: whitesmoke;
    box-shadow: 2px 2px 10px 1px rgba(0, 195, 255, 0.294);
    color: rgb(0, 133, 250);
    // width: calc(100% - 300px);
    height: 60px;
    @include flex(center, center, row, nowrap);
    font-size: larger;
    font-weight: 600;
  }

  .zoomSchedule-username {
    font-size: larger;
    max-width: 600px;
    margin-left: 30px;
    margin-top: 50px;
  }

  .zoomSchedule-Btn-Row {
    @include flex(flex-end, center, row, nowrap);
    margin: 30px;

    .zoomSchedule-viewSyllabus {
      height: 40px;
      width: 120px;
      background-color: rgb(0, 133, 250);
      @include flex(center, center, row, nowrap);
      color: whitesmoke;
      padding: 5px;
      border-radius: 5px;
    }
  }

  .zoomSchedule-Card {
    margin: 20px auto 0px auto;
    height: 200px;
    max-width: 1000px;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    transition: all 0.2s;
    @include flex(space-between, center, row, nowrap);

    &:hover {
      transform: scale(1.01);
    }

    .zoomSchedule-Card-Left {
      // box-shadow: 2px 2px 10px 1px black;
      cursor: pointer;
      width: 200px;
      height: 100%;
      background-color: rgb(65, 157, 68);
      color: rgb(241, 240, 255);
      @include flex(center, center, column, nowrap);
      border-radius: 5px;
      padding: 10px;
      box-sizing: border-box;

      p {
        margin: 2px;
        &:nth-of-type(2) {
          font-size: 50px;
        }
      }
    }

    .zoomSchedule-Card-Right-Row {
      width: 90%;
      background-color: whitesmoke;
      height: 100%;
      @include flex(space-between, center, row, nowrap);

      .zoomSchedule-Card-Right-Div1 {
        margin-left: 50px;
        max-width: 300px;
      }

      .zoomSchedule-Card-Right-Div2 {
        @include flex(space-between, center, column, nowrap);
        margin-left: auto;
        margin-right: 40px;
        cursor: pointer;
        color: #2c99f2;

        .zoomSchedule-Card-Right-Div2-icons {
          font-size: 50px;
        }
      }

      .zoomSchedule-Card-Right-Div3 {
        height: 100%;
        width: 10px;
        background-color: rgb(65, 157, 68);
        border-radius: 5px;
      }
    }
  }
}

@import "../ZoomScheduleMediaquery/ZoomScheduleMediaquery.scss";
