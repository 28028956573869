@import "../../../Scss/ScssMixins.scss";

$theme: rgb(0, 174, 255);

.notification {
  width: 100%;
  box-sizing: border-box;
  padding: 30px 30px 0px 30px;
  color: rgb(92, 90, 90);
  font-size: 1.6rem;

  .notification-back-btn {
    @include flex(flex-start, center, row, nowrap);
    margin: 20px 0px;
    width: 160px;
    font-size: 20px;
    color: $theme;
    cursor: pointer;

    &:hover {
      color: rgb(0, 65, 168);
    }

    .notification-back-icon {
      font-size: 40px;
    }
  }

  .notification-content {
    cursor: pointer;
    margin: 30px 20px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 2px 2px 20px 1px rgba(10, 198, 255, 0.13);
    // border: 0.5px solid rgba(128, 128, 128, 0.253);
    @include flex(flex-start, center, row, nowrap);

    img {
      margin: 30px;
      //   background-color: aqua;
    }

    p {
      max-width: 1000px;
    }
  }
}

// mediaquery

@media only screen and (max-width: 600px) {
  .notification {
    img {
      margin: auto;
    }
    .notification-content {
      margin: 30px 0px;
      @include flex(center, center, row, wrap);
      padding: 20px;
      box-sizing: border-box;
    }
  }
}
