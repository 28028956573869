.zoom-div {
  height: 100vh;
  width: 100vw;
  position: relative;
  background: rgb(5, 83, 109);
  .zoomPage {
    height: 100%;
    width: 100%;
    background: url("bg_bg.png");
    background-size: 500px auto;
    background-blend-mode: soft-light;
    background-color: rgb(20, 20, 20);
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
      outline: none;
      border: none;
      border-radius: 8px;
      position: fixed;
      top: 10px;
      left: 10px;
      background: rgb(0, 0, 0);
      height: 50px;
      width: 100px;
      color: white;
      font-size: 20px;
      cursor: pointer;
    }
    .zoom-page-div {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      height: 90%;
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgb(221, 221, 221);
      border-radius: 10px;
      overflow: hidden;
      .i-f {
        border: none;
        outline: none;
        padding: 0;
        margin: 0;
        height: 100%;
        width: 100%;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.147);
      }
    }
  }
}
