@media only screen and (max-width: 900px) {
  .loginOTP {
    .loginOTP-Card-Top {
      //   background-color: rgb(0, 204, 255);
      margin: 10px;
      width: 90%;
      > h1 {
        font-weight: 500;
        font-size: 30px;
      }

      .loginOTP-Card-Top-Textfield {
        > input {
          width: 30px;
          //   outline: 1px solid black;
        }
      }
    }

    .loginOTP-Card-Bottom {
      display: none;
    }
  }
}
