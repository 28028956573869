@import "../../../Scss/ScssMixins.scss";

.loading {
  min-height: 100vh;
}

.allCourses {
  margin-top: 30px;
  color: rgb(92, 90, 90);
  min-height: 100%;
  font-size: 1.6rem;
  @include flex(center, center, row, wrap);

  > .onlineCourses-card {
    max-width: 370px;
    margin: 50px;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.13);
    @include box(auto, auto, white, 15px);

    @include mediaquery-breakpoint(500px) {
      margin: 50px 20px;
      max-width: auto;
      width: 90%;
    }

    > .image {
      // background-image: url("../../../Image/courses/nodejs.png");
      background-size: cover;
      background-repeat: no-repeat;
      @include flex(center, flex-start);
      @include box(auto, 100%, rgb(0, 174, 255), 15px);
    }

    > .content {
      padding: 15px;
      box-sizing: border-box;

      .courseName {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 18px;
        margin: 10px 0px;
        font-weight: 400;
        color: rgb(0, 92, 154);
      }

      .description {
        font-size: 16px;
        text-overflow: ellipsis;
        max-lines: 2;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      div {
        &:nth-of-type(1) {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          p {
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              .online-icons {
                font-size: 30px;
                color: rgb(54, 105, 148);
                margin-right: 3px;
              }
            }
          }
        }
      }
    }
    .allCoursesBtn {
      @include box(50px, 90%, transparent);
      @include flex(center, center);
      border: 1px solid rgb(0, 174, 255);
      color: rgb(0, 174, 255);
      font-weight: 500;
      cursor: pointer;
      position: relative;
      margin: 0px auto 15px auto;
      text-decoration: none;
      overflow: hidden;

      span {
        z-index: 3;
        transition: 0.2s;
      }

      &:hover::before {
        height: 800px;
        width: 800px;
      }

      &:hover span {
        color: aliceblue;
      }

      &::before {
        content: "";
        height: 0px;
        width: 0px;
        position: absolute;
        top: var(--y);
        left: var(--x);
        background-color: rgb(18, 136, 0);
        border-radius: 50%;
        transition: width 0.6s, height 0.6s;
        z-index: 2;
        transform: translate(-50%, -50%);
      }
    }
  }
}
