input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inp-div {
  height: auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
  border-bottom: 2px solid #051c27;
  padding: 3px 5px;

  input,
  textarea {
    height: 100%;
    width: 300px;
    border: none;
    outline: none;
    background: transparent;
    font-size: 18px;
    resize: none;
    font-family: "Poppins";
    // transition: 0.2s;
  }
  .isNum {
    width: calc(300px - 34px);
    // transition: 0.5s;
    // padding-left: 10px;
  }
  .mobNum {
    color: #051c27;
    font-size: 18px;
  }
  ::placeholder {
    color: #2f2f2f;
    font-family: "arial";
    font-size: 18px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255);
    border-radius: 10px;
  }

  .ico {
    display: inline;
    font-size: 18px;
    color: #051c27;
  }
}

.t-a {
  align-items: flex-start;
}
.inValidFild {
  border: 2px solid rgb(222, 66, 66);

  border-radius: 5px;
}
