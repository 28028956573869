@media only screen and (max-width: 1200px) {
  .webinarLanding {
    background-color: aqua;
    margin-top: 120px;
  }
}

@media only screen and (max-width: 950px) {
  .webinarLanding {
    background-color: rgb(0, 255, 64);
    margin-top: 80px;
  }
}
