.rectangles {
  > div:nth-of-type(1) {
    position: absolute;
    bottom: 28%;
    left: 0%;
    height: 500px;
    width: 100px;
    background-color: yellowgreen;
    transform: rotate(130deg);
  }
  > div:nth-of-type(2) {
    position: absolute;
    bottom: 30%;
    left: 17px;
    height: 500px;
    width: 100px;
    background-color: yellow;
    transform: rotate(130deg);
  }

  @include mediaquery-breakpoint(500px) {
    display: none;
  }
}

.triangles {
  > div:nth-of-type(1) {
    position: absolute;
    top: 15%;
    right: 4%;
    width: 0;
    height: 0;
    border-left: 80px solid transparent;
    border-right: 80px solid transparent;
    border-bottom: 130px solid rgb(0, 255, 170);
    transform: rotate(-18deg);
  }
  > div:nth-of-type(2) {
    position: absolute;
    top: 18%;
    right: 3.6%;
    font-size: 180px;
    border-left: 80px solid transparent;
    border-right: 80px solid transparent;
    border-bottom: 130px solid cyan;
    transform: rotate(-18deg);
  }

  @include mediaquery-breakpoint(500px) {
    display: none;
  }
}

.circles {
  > div:nth-of-type(1) {
    position: absolute;
    bottom: 13%;
    right: 1%;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-color: pink;
    opacity: 0.5;
    //   transform: rotate(130deg);
  }
  > div:nth-of-type(2) {
    position: absolute;
    bottom: 22%;
    right: 90px;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    opacity: 0.7;
    background-color: violet;
    //   transform: rotate(130deg);
  }

  @include mediaquery-breakpoint(500px) {
    display: none;
  }
}
