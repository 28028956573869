%TopbarCol1 {
  > .col1 {
    @include flex(null, null, column);

    .backBtn {
      @include flex(space-between, center);
      max-width: 185px;
      font-size: 20px;
      cursor: pointer;

      .icon {
        font-size: 40px;
      }
    }

    .font1 {
      font-size: 30px;

      @include mediaquery-breakpoint(600px) {
        font-weight: 500;
        font-size: 25px;
        text-align: center;
      }
    }

    .row {
      @include flex(space-between, center);
      max-width: 270px;

      @include mediaquery-breakpoint(600px) {
        @include flex(center, center);
        gap: 20px;
        max-width: 100%;
      }

      > .main-icon {
        @include flex(center, center);
        gap: 10px;
      }
    }
  }
}
