@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

.pop-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  // background: rgba(0, 0, 0, 0.025);
  background: none;
  // -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(0px);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pop-up-div {
  position: fixed;
  height: auto;
  width: auto;
  top: 50%;
  left: 50%;
  background: #ffffff5b;
  border-radius: 10px;
  transform: translateX(-50%);
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  padding: 40px;
  box-sizing: border-box;
  flex-direction: column;
  // border: 5px solid rgba(187, 190, 193, 0.602);
  // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.185);
  z-index: 9999;

  h1 {
    font-family: "Poppins";
    font-weight: 500;
    color: #656565;
    color: #0a73be;
    margin-top: 5px;
    font-size: 20px;
  }
  .c-icon {
    font-size: 30px;
    color: #051c27;
    position: absolute;
    top: 20px;
    right: 25px;
    height: 35px;
    cursor: pointer;
  }
  .a-logo {
    // padding: 50px;
  }
  .text-fields {
    .d-d-d {
      position: relative;
      margin-bottom: 25px;
      .drop-down {
        display: flex;
        padding: 3px 5px;
        align-items: center;
        justify-content: space-between;
        color: #051c27;
        border-bottom: 2px solid #051c27;
        font-size: 18px;
        cursor: pointer;
        p {
          margin: 0;
        }
        .ico {
          font-size: 25px;
          transform: rotateZ(0deg);
        }
      }

      .options {
        position: absolute;
        width: 100%;
        top: calc(100%);
        left: 0;
        font-size: 1.6em;
        overflow: auto;
        box-sizing: border-box;
        border: 5px;
        background: rgba(255, 255, 255, 0.949);
        overflow: hidden;
        overflow-y: auto;
        .option {
          padding-left: 10px;
          height: 50px;
          display: flex;
          align-items: center;

          cursor: pointer;
        }
        .isSelect {
          background: rgba(195, 195, 195, 0.439);
        }
      }
    }
  }
  .en-btn {
    // background: rgb(0, 1, 10);
    background: #0a73be;
    color: white;
    font-size: 20px;
    padding: 10px 30px;
    border-radius: 8px;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
    transition: 0.2s;
    &:hover {
      background: rgb(6, 82, 137);
    }
  }
}
@media (max-width: 450px) {
  .pop-up-div {
    height: 98%;
    width: 98%;
  }
}
