.tech-fest-layout {
  font-family: "poppins";
  max-width: 1200px;
  width: 95%;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block: 15px;
  gap: 10px;
  cursor: pointer;
  .confetti-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;

    .confetti {
      position: absolute;
      width: 10px;
      height: 20px;
      animation: fall 3s infinite ease-in-out;
    }
  }

  .techImg {
    img {
      width: 100%;
      max-width: 240px;
    }
  }

  .center-section {
    text-align: center;

    .title {
      font-size: 24px;
      font-weight: 700;
      margin-block: 5px;
      color: #f2801e;
    }

    .details {
      font-size: 16px;
      color: black;
      font-weight: 600;
      margin-block: 5px;
    }
  }

  .book-tickets-image {
    img {
      width: 100%;
      max-width: 100px; /* Reduced image size for desktop */
      min-width: 100px;
      height: auto;
      object-fit: contain;
      cursor: pointer;
      animation: dance 1s infinite ease-in-out;
    }
  }

  @media screen and (max-width: 1150px) {
    .center-section {
      .title {
        font-size: 20px; /* Slightly smaller for medium devices */
      }
      .details {
        font-size: 16px;
      }
    }
  }
}

/* Animations */
@keyframes dance {
  0%,
  100% {
    transform: rotate(-5deg) scale(1);
  }
  50% {
    transform: rotate(5deg) scale(1.1);
  }
}

@keyframes fall {
  0% {
    transform: translateY(-100%) rotate(0deg);
    opacity: 1;
  }

  100% {
    transform: translateY(100vh) rotate(360deg);
    opacity: 0;
  }
}
/* Responsive Design */
@media screen and (max-width: 768px) {
  .tech-fest-layout {
    display: flex;
    justify-content: center;
    // background-color: #f0c;
    max-width: 600px;
  }

  .techImg {
    justify-content: space-between;
    width: 100%;
    margin-inline: auto;
    // background-color: #f0c;
    img {
      width: 180px !important;
    }
  }

  .book-tickets-image {
    // background-color: #f2801e;
    img {
      max-width: 80px !important; /* Reduced image size for desktop */
      min-width: 80px !important;
    }
  }

  .techfest-content {
    justify-content: center;
    margin-left: 5px;
  }

  .text-section {
    margin-top: 20px;
    .title {
      color: #f2801e;
      text-align: center;
      font-size: 16px; /* Slightly smaller for medium devices */
      margin-block: 5px;
    }

    .details {
      color: black;
      text-align: center;
      font-size: 14px; /* Slightly smaller for medium devices */
      font-weight: 600;
      margin-block: 5px;
    }
  }
}
