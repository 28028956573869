.all-topic-div {
  display: flex;
  justify-content: flex-start;

  flex-wrap: wrap;
  .topic-card {
    width: 350px;
    height: 80px;
    background-color: rgba(13, 121, 245, 0.2);
    margin: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    display: inline-flex;
    cursor: pointer;
    transition: 0.2s;

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      .avatar {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(13, 121, 245, 0.2);
        margin-right: 15px;
        span {
          font-size: 40px;
          font-weight: bold;
          color: rgba(13, 121, 245, 0.8);
        }
      }
      .topic {
        font-size: 20px;
        color: rgba(13, 121, 245, 0.8);
      }
    }
    .ico > * {
      font-size: 40px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .all-topic-div {
    justify-content: center;
  }
}
