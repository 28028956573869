@import "../../../Scss/ScssMixins.scss";

@import "./TopbarCol1.scss";
@import "./TopbarCol2.scss";

%Topbar {
  .bgColor {
    background-color: rgb(0, 93, 139);
    @include flex(center);
    .maxWidth {
      width: 1700px;

      // @include mediaquery-breakpoint(1200px) {
      //   width: 800px;
      // }

      .topbar {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        color: aliceblue;
        padding: 40px;
        box-sizing: border-box;
        position: relative;

        @include mediaquery-breakpoint(1200px) {
          grid-template-columns: repeat(1, 1fr);
          justify-items: center;
          padding: 40px 20px;
        }

        @extend %TopbarCol1;
        @extend %TopbarCol2;
      }
    }
  }
}
