.webinar-zoom {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../LoggedInScreens/ZoomSchedule/bg_bg.png");
  background-size: auto 450px;
  background-blend-mode: soft-light;
  background-color: rgb(20, 20, 20);
  height: 100vh;
  width: 100vw;

  .webinar-error {
    // padding: 100px;
    min-width: 400px;
    height: 150px;
    height: 150px;
    width: 400px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    font-size: 20px;
  }

  .webinar-if {
    height: calc(100vh);
    width: calc(100vw);
    border: none;
    outline: none;
  }
}
