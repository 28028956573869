@import "../../../Scss/ScssMixins.scss";

.row {
  @include flex(flex-start, flex-start, row, wrap);
  .myCourse {
    width: calc(100% - 300px);
    @include flex(center, center, row, wrap);
    padding: 50px 20px;
    box-sizing: border-box;
    gap: 15px;
    margin-left: auto;

    @include mediaquery-breakpoint(1100px) {
      margin: auto;
      width: 100%;
    }

    .myCourseCard {
      background-color: white;
      border-radius: 10px;
      width: 360px;
      margin: 25px;
      box-shadow: 2px 2px 30px 1px rgba(0, 0, 0, 0.233);
      transition: all 0.1s;
      font-size: 1.6rem;
      @include flex(flex-start, center, column, nowrap);

      @include mediaquery-breakpoint(600px) {
        margin: 25px 0px;
      }

      &:hover {
        transform: scale(1.01);
      }

      div {
        &:nth-of-type(1) {
          border-radius: 10px;
          background-color: rgb(0, 133, 250);
          background-size: cover;
          height: 180px;
          width: 100%;

          @include mediaquery-breakpoint(360px) {
            height: 150px;
          }
        }
      }

      .courseName {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 19px;
        margin: 10px 0px;
        font-weight: 400;
        color: rgb(0, 92, 154);
      }

      .description {
        font-size: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .myCourse-content {
        margin: 0px 10px;
      }

      .myCourse-Btn {
        // cursor: none;
        height: 40px;
        width: calc(100% - 30px);
        margin: 10px 10px 15px 10px;
        border-radius: 5px;
        border: 1px solid rgb(0, 174, 255);
        color: rgb(0, 174, 255);
        @include flex(center, center, row, nowrap);
        text-decoration: none;
        overflow: hidden;
        position: relative;
        z-index: 1;

        &:hover::before {
          height: 800px;
          width: 800px;
        }

        &:hover span {
          color: white;
        }

        &::before {
          content: "";
          height: 0px;
          width: 0px;
          position: absolute;
          top: 0px;
          left: 0px;
          background-color: rgb(25, 148, 0);
          // background-color: rgb(0, 97, 126);
          border-radius: 50%;
          transition: width 0.5s, height 0.5s;
          z-index: 2;
          transform: translate(-50%, -50%);
        }

        span {
          z-index: 3;
        }
      }
    }
  }
}
