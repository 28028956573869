@import url("https://fonts.googleapis.com/css2?family=Redressed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "../../../Scss/ScssMixins.scss";

.loading {
  @include flex(center, center, row, nowrap);
  height: 50vh;
}

.onlineCourses-title {
  color: rgb(0, 116, 170);
  text-align: center;
  font-weight: 300;
  font-size: 40px;
  font-variant: small-caps;
}

.onlineCourses {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(92, 90, 90);
  @include flex(center, flex-start, row, wrap);
  font-size: 1.6rem;

  > .onlineCourses-card {
    max-width: 360px;
    background-color: white;
    border-radius: 15px;
    margin: 40px 40px;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.13);
    transition: 0.3s;

    &:hover {
      transform: scale(1.05);
    }

    > .image {
      display: flex;
      justify-content: center;
      background-color: rgb(0, 174, 255);
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 15px;
      width: 100%;
      // height: 200px;

      img {
        width: 100%;
        height: auto;
        border-radius: 15px;
      }
    }

    > .onlineCourses-content {
      padding: 15px;
      box-sizing: border-box;
      .topic {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 20px;
        margin: 10px 0px;
        font-weight: 500;
        color: rgba(0, 116, 170, 0.785);
      }
      > .description {
        font-size: 15px;
        margin: 10px 0px;
        font-weight: 400;
        text-overflow: ellipsis;
        max-lines: 2;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      > div:nth-of-type(1) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        > p {
          > span {
            @include flex(center, center, row, nowrap);
            > .online-icons {
              font-size: 22px;
              color: rgb(54, 105, 148);
              margin-right: 3px;
            }
          }
        }
      }
    }
  }
}

.onlineCourses
  > .onlineCourses-card
  > .onlineCourses-content
  > div:nth-of-type(1)
  > p {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* btn */

.onlineCourses-View-Btn {
  width: 90%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(0, 174, 255);
  font-weight: 500;
  border: 1px solid rgb(0, 174, 255);
  border-style: inset;
  cursor: pointer;
  position: relative;
  margin: 0px auto 15px auto;
  text-decoration: none;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    left: -13%;
    height: 100%;
    width: 0%;
    transition: all 0.5s;
    background-color: rgb(0, 115, 182);
    transform: skewX(60deg);
  }

  &::after {
    content: "";
    position: absolute;
    right: -13%;
    height: 100%;
    width: 0%;
    transition: all 0.5s;
    background-color: rgb(0, 94, 148);
    transform: skewX(60deg);
  }

  > span {
    z-index: 2;
  }
}

/* btn hover */

.onlineCourses > .onlineCourses-card > .onlineCourses-View-Btn:hover::before,
.onlineCourses > .onlineCourses-card > .onlineCourses-View-Btn:hover::after {
  width: 64%;
}

.onlineCourses > .onlineCourses-card > .onlineCourses-View-Btn:hover span {
  color: aliceblue;
}

/* mediaquery card mobile responsive */

@import "./OnlineCoursesResponsive.scss";
