/* Chatbot container */
.chatbot-container-active {
  display: block !important;
}

.chatbot-container {
  display: none;
  position: fixed;
  right: 100px;
  bottom: 20px;
  background-color: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.188);
  max-width: 360px;
  min-height: calc(87vh - 63%);
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  margin: auto;
  z-index: 999;

  /* Chat messages container */
  .chatbot-messages {
    overflow-y: auto;
    padding: 20px 10px;
    height: 450px;
  }

  /* Individual message style */
  .message {
    padding: 12px 12px;
    width: 80%;
  }

  /* User message style */
  .message.user {
    background-color: #2196f3;
    color: white;
    align-self: flex-end;
    float: right;
    width: 50%;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-end-start-radius: 25px;
    border-start-end-radius: 25px;
    border-start-start-radius: 25px;
  }

  /* Bot message style */
  .message.bot {
    background-color: #eef8fe;
    font-size: 15px;
    color: #696565;
    line-height: 24px;
    padding: 20px 10px;
    border-end-end-radius: 30px;
    border-end-start-radius: 30px;
    border-start-end-radius: 30px;
  }

  /* Chat input container */
  .chatbot-input {
    display: flex;
    padding: -34px;
    margin-top: -19px;
    width: 90%;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: auto;
  }

  .select-button {
    background-color: white;
    border: none;
    color: #2196f3;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    line-height: 24px;
  }

  .main-select-btn {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 13px;
  }

  .message-div-con {
    float: right;
  }

  .header-container {
    background-color: #2196f3;
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 15px
  }

  .close-chatbot {
    color: white;
  }

  .close-icon {
    height: 35px;
    cursor: pointer;
    margin-left: 58px;
  }

  .message-div-con {
    cursor: pointer;
    /* position: absolute;
  right: 30px;
  bottom: 71px; */
  }

  * {
    font-family: Poppins, sans-serif, Ubuntu;
  }

  .footer-container {
  height: 56px;
    background-color: #2196f3;
    width: 100%;
    display: flex;
    align-items: center;
    color: white;
    font-size: 18px;
    gap: 14px;
    justify-content: space-around;
    padding: 10px;
  }
  

  .inner-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-size: 14px;
  }

  .inner-chat-message-bot {
    display: flex;
    align-items: flex-end;
  }

  #chatbot-image:hover {
    animation: tilt-shaking 0.25s infinite;
  }



  .chatbot-tooltip {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #cf0e0e;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
  }

  .chatbot-tooltip:hover {
    visibility: visible;
  }

  #chatbot-image:hover .chatbot-tooltip {
    visibility: visible;
  }

        @keyframes tilt-shaking {
          0% {
            transform: rotate(0deg);
          }
    
          25% {
            transform: rotate(5deg);
          }
    
          50% {
            transform: rotate(0eg);
          }
    
          75% {
            transform: rotate(-5deg);
          }
    
          100% {
            transform: rotate(0deg);
          }
        }

}

@media(max-width:1000px) {
  .chatbot-container {

    width: 430px;
  }

  .chatbot-container .message.bot {
    font-size: 17px;
  }

  .chatbot-container .select-button {
    font-size: 15px;
  }

  .chatbot-container .inner-footer {
    font-size: 14px;
  }
}

@media(max-width:850px) {
  .chatbot-container {

    width: 400px;
  }

  .chatbot-container .message.bot {
    font-size: 16px;
  }
.chatbot-container .message.user{
  font-size: 16px;
}
  .chatbot-container .select-button {
    font-size: 14px;
  }

  .chatbot-container .inner-footer {
    font-size: 13px;
  }

  .chatbot-container .call-option {
    width: 25px;
  }

  .chatbot-container .chatbot-messages {
    padding: 14px 10px;
  }
    .chatbot-container .profile-image{
    width: 27px;
  }
}

@media(max-width:600px) {
  .chatbot-container {

    width: 370px;
  }

  .chatbot-container .message.bot {
    font-size: 15px;
  }
.chatbot-container .message.user {
  font-size: 15px;
}
  .chatbot-container .select-button {
    font-size: 13px;
  }

  .chatbot-container .inner-footer {
    font-size: 12px;
  }

  .chatbot-container .call-option {
    width: 22px;
  }

  .chatbot-container .chatbot-messages {
    padding: 12px 8px;
  }

  .chatbot-container .oa_logo {
    width: 222px;
  }

  .chatbot-container .close-icon {
    margin-left: 76px;
  }
    .chatbot-container .profile-image {
      width: 25px;
    }
        .chatbot-container .tick-imag{
      width: 18px;
    }
}

// 
@media(max-width:500px) {
  .chatbot-container {

    width: 330px;
  }

  .chatbot-container .message.bot {
    font-size: 15px;
  }

  .chatbot-container .message.user {
    font-size: 14px;
  }

  .chatbot-container .select-button {
    font-size: 13px;
  }

  .chatbot-container .inner-footer {
    font-size: 12px;
  }

  .chatbot-container .call-option {
    width: 22px;
  }

  .chatbot-container .chatbot-messages {
    padding: 12px 8px;
  }

  .chatbot-container .oa_logo {
    width: 222px;
  }

  .chatbot-container .close-icon {
    margin-left: 31px;
  }

  .chatbot-container .profile-image {
    width: 25px;
  }
 .chatbot-container .message.user{

  margin-top: 10px;
  margin-bottom: 10px;
  }
         .chatbot-container .tick-imag {
           width: 15px;
         }
}

@media(max-width:460px){
  .chatbot-container{
    position: fixed;
      left: 11%;
  }

}
@media(max-width:410px) {
  .chatbot-container {
    position: fixed;
    left: 8%;
  }

}
@media(max-width:370px) {
  .chatbot-container {
    position: fixed;
    left: 5%;
  }

}
@media(max-width:355px) {
  .chatbot-container {
    position: fixed;
    left: 2%;
  }

}